/**
 * Container
 *
 * Containers are for the container of content or a starting point of the Grid
 * module. This puts the content to be in the center with a maximum width of
 * either the standard size of 1200px, or larger, or smaller.
 *
 * .Container--tablet - Set the size to be 768px.
 * .Container--desktopSmall - Set the size to be 960px.
 * .Container--desktopXLarge - Set the size to be 1400px.
 * .Container--fluid - Set the size to be 100% of the width.
 *
 * Markup: container.html
 *
 * Styleguide: Grid System - Container
 */
@import (reference) "theme/variables.less";

.Container {
    width: 100%; /* Flexbox compatibility */
    max-width: @breakpoint-desktop-large + @spacing-mid;
    margin: 0 auto;
    padding: 0 @spacing-xsmall;

    @media (min-width: @breakpoint-tablet) {
        padding: 0 @spacing-mid / 2;
    }

    &--tablet {
        max-width: @breakpoint-tablet;
    }

    &--desktopSmall {
        max-width: @breakpoint-desktop-small;
    }

    &--desktopXLarge {
        max-width: @breakpoint-desktop-xlarge;
    }

    &--fluid {
        max-width: none;
    }
}
