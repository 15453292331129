@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.Footer {
    @this: Footer;

    .type(ui);
    background: @footer-bg-color;
    color: @text-color-light;
    position: relative;

    &-section {
        padding: @spacing-large 0;

        &--bordered {
            border-bottom: 1px solid @quaternary-color-dark;
        }

        &:last-child {
            @media (min-width: @breakpoint-desktop-small) {
                padding-bottom: @spacing-xlarge;
            }

            @media (min-width: @breakpoint-desktop-xlarge) {
                padding-bottom: @spacing-large;
            }
        }
    }

    &-siteLinks {
        &:not(:last-child) {
            margin-bottom: @spacing-mid;

            @media (min-width: @breakpoint-desktop-small) {
                margin-bottom: 0;
            }
        }

    }

    &-linksTitle {
        .type(landing);
        color: inherit;
        font-weight: bold;
        margin-bottom: @spacing-small;
    }

    &-links {
        .list-unstyled();
        margin-bottom: @spacing-small;

        @media (min-width: @breakpoint-desktop-small) {
            margin-bottom: 0;
        }

        &--inline {
            margin-bottom: 0;

            @media (min-width: @breakpoint-tablet) {
                display: flex;
            }
        }

        &--topLinks {
            justify-content: space-between;
        }
    }

    &-link {
        color: @footer-text-color;
        display: inline-block;
        margin-bottom: @spacing-xxsmall;
        transition: color 0.3s ease-out;

        &:hover {
            color: @text-color-light;
            text-decoration: none;
        }

        &--large {
            .type(ui);
            font-weight: bold;
            text-transform: uppercase;
        }

        &--inline {
            margin-right: @spacing-small;

            @media (min-width: @breakpoint-tablet) {
                margin-bottom: 0;
            }
        }

        &--bordered:not(:last-child) {
            @media (min-width: @breakpoint-tablet) {
                border-right: 1px solid @border-color-light;
                margin-right: @spacing-xsmall;
                padding-right: @spacing-xsmall;
            }
        }
    }

    &-appStoreIcons {
        /* Because the app store icons keep having an explicit line height,
           just convert the whole container to flexbox model instead. */
        display: flex;
        flex-flow: row wrap;
    }

    &-appStoreIcon {
        display: inline-block;
        margin-top: @spacing-small;

        @media (min-width: @breakpoint-tablet) {
            margin-top: 0;
        }

        &:hover {
            text-decoration: none;
        }

        &:first-child {
            margin-right: @spacing-small;
        }

        &-image {
            display: block;
        }
    }

    &-socialMediaIcon {
        fill: @footer-text-color;

        &:not(:last-child) {
            margin-right: @spacing-small;
        }
    }

    &-copyright {
        .type(ui);
        color: @footer-text-color;
        margin: @spacing-small 0 0;

        /* Off the chance that the copyright is the first element, don't put
           a margin */
        &:first-child {
            margin-top: 0;
        }
    }

    &-localeSelectorWrapper {
        @media (min-width: @breakpoint-tablet) {
            display: flex;
        }
    }

    &-localeSelectorAccessibility {
        align-items: center;
        border: 1px solid @quaternary-color-xxlight;
        color: @quaternary-color-xxlight;
        display: flex;
        line-height: 0;
        margin-bottom: @spacing-small;
        margin-top: @spacing-small;
        padding: 8px 18px;
        width: fit-content;

        @media (min-width: @breakpoint-tablet) {
            margin-bottom: 0;
            margin-left: @spacing-small;
            margin-top: 0;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            cursor: pointer;
        }
    }

    &-localeSelector {
        margin-bottom: @spacing-mid;

        @media (min-width: @breakpoint-tablet) {
            margin-bottom: 0;
        }

        &-btn {
            border-color: @footer-text-color;
            color: @footer-text-color;
        }

        &-icon {
            fill: @footer-text-color;
        }
    }

    &--compact {
        .@{this}-link,
        .@{this}-copyright {
            .type(detail);
        }

        .@{this}-copyright {
            margin: @spacing-xxsmall 0 0;
            text-align: right;

            &:first-child {
                margin-top: 0;
            }
        }

        .@{this}-section {
            padding: 0;

            &:first-child,
            &:last-child {
                padding: @spacing-mid 0;
            }
        }
    }

    &-country {
        margin-bottom: @spacing-mid;

        @media (min-width: @breakpoint-tablet) {
            margin-bottom: 0;
            margin-top: @spacing-mid;
            order: 1;
        }

        &-links {
            width: 100%;
        }

        &-link {
            display: inline-block;
            float: left;
            width: 50%;

            @media (min-width: @breakpoint-tablet) {
                margin-right: @spacing-small;
                width: auto;
            }
        }
    }

    &-locale {
        @media (min-width: @breakpoint-tablet) {
            flex: 0 1 70%;
        }
    }

    &-social {
        @media (min-width: @breakpoint-tablet) {
            flex: 0 1 30%;
        }
    }

    &-logo {
        display: inline-block;

        &-freightlancerImg {
            width: 235px;
        }
    }
}
