/*************************************************/
/* profile pic */
@import (reference) "theme/variables.less";

.profile-img {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: url("/static/img/legacy_public_img/avatar-placeholder.svg");
    background-size: 100%;
    img {
        position: relative;
        z-index: 1;

        width: 100%;
        height: auto;
        vertical-align: top;
    }
}

.profile-placeholder-image {
    width: 100%;
}

/*************************************************/
/*  Profile placeholder lets us place the users initial as their default profile img.
    The size of the initial has to be done manually per instance.
 */

.profile-placeholder {
    display: table;
    vertical-align: middle;
    z-index: 1;

    width: 100%;
    height: 100%;

    background-color: @quaternary-color;
    color: @septenary-color-xxxlight;
    background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0), @quaternary-color-dark);

    font-weight: bold;
    text-align: center;
}

.profile-placeholder-initial {
    display: table-cell;
    vertical-align: middle;
    line-height: 1.6em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

/*************************************************/
/* user status */

/* sizes */
.user-status-small {
    &:before {
        border-width: 0 0 15px 15px;
    }
    &:after {
        border-width: 0 0 13px 13px;
    }
}
.user-status-med {
    &:before {
        border-width: 0 0 23px 23px;
    }
    &:after {
        border-width: 0 0 20px 20px;
    }
}
