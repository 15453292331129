/**
 * Dropdown
 *
 * Dropdowns are graphical control elements, that allows users to choose one value from a list.
 * When activate it displays a list of values, from which the user may select one.
 * The name FlDropdown is due to the fact that a dropdown.less is already in use and we need to
 * deprecate it before we can rename this module
 * Style FlDropdown.less
 *
 * Markup: dropdown.html
 *
 * Styleguide: Dropdown
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "mixins/animated/fadeIn.less"; /* Adding this to fix build errors on Pattern Library */

.DropDownTrigger {
    &:hover {
        .DropDown {
            display: block;
        }
    }
}

.DropDown {
    @this: DropDown;

    .box-shadow-level-02();
    .animated(0.2s);
    .fadeIn();
    flex-direction: column;
    z-index: @z-index-dropdown;
    width: 280px;
    padding: @spacing-large 0;
    border-radius: @border-radius;
    background: @septenary-color-xxxlight;

    &-section {
        &-header {
            &-heading {
                .type(ui);
                margin-bottom: @spacing-mid;
                padding: 0 @spacing-large;
                text-align: left; // Needed to override parent text alignment in navbar
                text-transform: uppercase;
            }
        }
    }

    &-list {
        .list-unstyled();
        display: flex;
        flex-direction: column;
        margin: -@spacing-xsmall 0;

        &-item {
            .type(ui);
            position: relative;
            padding: @spacing-xsmall @spacing-large;
            text-align: left; // Needed to override parent text alignment in navbar
            color: @text-color-dark;

            &-inner {
                &::before {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    border-left: 3px solid @primary-color;
                    content: "";
                    transition: opacity 0.2s ease;
                }

                &:hover {
                    &::before {
                        opacity: 1;
                    }

                    .@{this}-list-item-link {
                        text-decoration: none;
                        color: @primary-color;
                    }
                }
            }

            &-link {
                display: block;
                text-decoration: none;
                color: @text-color-dark;
                transition: color 0.2s ease;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &-divider {
        width: auto;
        margin: @spacing-small @spacing-large;
        border-top: 0;
        border-bottom: 1px solid @border-color-light;
    }

    &--small {
        width: 160px;
    }

    &--mid {
        width: 250px;
    }

    &--large {
        width: 480px;
    }

    &-body {
        padding: 0 @spacing-mid;
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 @spacing-mid;
    }

    &--arrowed {
        &::after {
            position: absolute;
            top: 2px;
            left: @spacing-xlarge;
            width: 0;
            height: 0;
            border: 8px solid @septenary-color-xxxdark;
            border-color: transparent transparent @septenary-color-xxxlight @septenary-color-xxxlight;
            content: "";
            box-shadow: -1px 1px 0 0 rgba(0, 0, 0, 0.1);
            transform: rotate(135deg);
            transform-origin: 0 0;
        }
    }
}
