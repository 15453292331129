@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "libs/normalize.less";
@import "theme/Container.less";
@import "theme/Grid.less";
/* -----------------------
Only to be edited by the design team - style changes here echo across the whole
site.
-------------------------- */

/* Overrides for compatibility issues on us transitioning from Normalize v2 to
   v4 start here */

/* Our figure elements are used to having no margins at all from v2 */
figure {
    margin: 0;
}

/* -webkit-input-placeholder was set to have a color of #999 somewhere. Disable
   the opacity: 0.54 rule for now and set a base color for inputs everywhere */
::-webkit-input-placeholder {
    opacity: 1;
}

/* Normalize v2's removal of most spacing between table cells. */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Override -webkit-appearance- as it has broken some non-overridden buttons
   to show the default styling. */
html [type="button"] {
    -webkit-appearance: none;
}

/* Normalize v4 overrides stop here */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    .primary-font();
    min-width: 960px;
    line-height: 1.4;
    background-color: @site-bg-color;

    .is-mobile-first & {
        min-width: 0;
    }
}

main,
.main-content {
    @font-size : 14px;
    .clearfix();
    display: block;
    min-height: 70vh;
    font-size: @font-size;
    line-height: 1.4;
    color: @text-color-dark;
    background: @site-bg-color-dark;
}

fieldset {
    padding: 0;
    border-color: transparent;
}

strong {
    font-weight: bold;
}

/*  1. Don't go below 12px for fonts */

small {
    .type(detail); /* 1. */
}

h1 {
    margin-top: 0;
    font-weight: bold;
}

h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

hr {
    width: 100%;
    margin: 30px 0;
    border-top: 1px solid @septenary-color-xxxlight;
    border-bottom: 1px solid @border-color-light;
}

code {
    font-weight: bold;
}

[class*="fl-icon-"] {
    .transition(~"color .1s ease-out");
}

legend {
    font-weight: bold;
}

textarea,
input {
    -webkit-font-smoothing: antialiased;
}

textarea:focus,
input:focus {
    outline: 0;
}

*:focus {
    outline: 0;
}

.LandingHeading {
    .type(hero);
}

.LandingByline {
    .type(heading2);
}

.Byline {
    .type(heading3);
}

a {
    text-decoration: none;
    color: @link-color;
    cursor: pointer;
    transition: color 0.1s ease-out;

    &:hover {
        text-decoration: underline;
        color: @link-color-hover;
    }

    &:focus {
        outline: 1px dotted @link-color;
        outline-offset: -1px;
    }

    &:hover,
    &:active {
        outline: 0;
    }
}

h1 {
    .type(heading1);
    margin-bottom: 26px;
    font-weight: bold;
}

h2 {
    margin-bottom: 22px;
    .type(heading1);
}

h3 {
    margin-bottom: 20px;
    .type(heading2);
}

h4 {
    margin-bottom: 18px;
    font-size: 22px;
}

h5 {
    margin-bottom: 16px;
    .type(heading3);
}

h6 {
    margin-bottom: 14px;
    .type(ui);
}

p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin-bottom: 24px;
    line-height: 1.4;
}

sup {
    font-size: 55%;
    vertical-align: middle;
}

.text-list {
    margin-bottom: 24px;
    padding: 0 0 0 16px;
    line-height: 1.4;

    li {
        margin-bottom: 8px;
    }
}

.large-text {
    font-size: 1.1rem;
}

.uppercase-text {
    text-transform: uppercase;
}

/**
 * Input template.
 * This standardises the color of the placeholder text.
 */
// FIXME: This selector is intentional to bump up the specificity by 10 in order
// to make it work on IE (IE has no concept of placeholders as pseudo elements
// so it inherits the placeholder color from the input color).
html:not(:empty) ::placeholder {
    opacity: 1;
    color: @placeholder-text-color;
}
