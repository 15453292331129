.triangle {
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    content: '';
    -moz-transform: scale(.9999);
}

.expand-icon(@color, @hover-color, @top-position:0, @right-position:0) {
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
        .triangle;
        z-index: 2;
        right: @right-position;
    }
    &:before {
        .transition(.1s, ease-out);
        top: @top-position;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent @color transparent;
    }
    &:after {
        .transition(.1s, ease-out);
        top: @top-position + 6px;
        border-width: 4px 4px 0 4px;
        border-color: @color transparent transparent transparent;
    }
    &:hover {
        &:before {
            top: @top-position - 2px;
            border-color: transparent transparent @hover-color transparent;
        }
        &:after {
            top: @top-position + 8px;
            border-color: @hover-color transparent transparent transparent;
        }
    }
}
