/**
 * ImageThumbnail
 *
 * This module is used for styling avatars and profile pictures. By default
 * it has a 48x48 size.
 *
 * .ImageThumbnail-link is an optional subcomponent that can be added to the
 * module to have a link for the module and enable the hover state.
 *
 * Documentation of this module from the Design team can be found
 * [here](https://confluence.flnltd.com/display/FREEL/Avatar).
 *
 * Markup: ImageThumbnail.html
 *
 * .ImageThumbnail--xsmall     - A 16x16 size.
 * .ImageThumbnail--small      - A 32x32 size.
 * .ImageThumbnail--large      - A 64x64 size.
 * .ImageThumbnail--xlarge     - A 96x96 size.
 * .ImageThumbnail--fluid      - Make the module span 100% width of the
 *                               container.
 * .ImageThumbnail--circle     - Make the module round.
 * .ImageThumbnail--inline     - Make the module function as an inline element.
 * .ImageThumbnail--clickable  - A helper modifier to enable the hover state
 *                               without .ImageThumbnail-link. Should **only**
 *                               be used when the module is clickable and
 *                               the interaction is handled by JavaScript.
 *
 * Styleguide Thumbnails - ImageThumbnail
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

@ImageThumbnail-size: 48px;
@ImageThumbnail-size--xsmall: 16px;
@ImageThumbnail-size--small: 32px;
@ImageThumbnail-size--mid: 40px;
@ImageThumbnail-size--large: 64px;
@ImageThumbnail-size--xlarge: 96px;
@ImageThumbnail-borderRadius: 3px;

/* Private constructor mixin */
.ImageThumbnail-sizeConstructor(@size) {
    height: @size;
    width: @size;
}

.ImageThumbnail-innerShadowConstructor() {
    &::before {
        .absolute-cover();
        background: @quaternary-color-xxdark;
        border-radius: inherit;
        content: "";
        cursor: pointer;
        .transition(all .2s ease-in);
        opacity: 0;
        z-index: 0;
    }

    &:hover {
        &::before {
            opacity: 0.4;
        }
    }
}

.ImageThumbnail {
    background: url("/static/img/legacy_public_img/avatar-placeholder.svg") no-repeat center / cover;
    border-radius: @ImageThumbnail-borderRadius;
    margin: 0 auto;
    position: relative;
    .ImageThumbnail-sizeConstructor(@ImageThumbnail-size);

    &--corporate {
        background: url("/static/img/legacy_public_img/avatar-placeholder-corporate.svg") no-repeat center / cover;
    }

    &::before {
        .absolute-cover();
        border-radius: @ImageThumbnail-borderRadius;
        box-shadow: inset 0 0 0 1px fade(@septenary-color-xxxdark, 12%);
        content: "";
    }

    &-link {
        border-radius: inherit;
        display: block;
        position: relative;
    }

    &-image {
        border-radius: inherit;
        display: block;
        overflow: hidden;
        width: 100%;

        /* Use thumbnail as background image */
        &--background {
            object-fit: cover;
        }
    }

    /* Positional modifiers */
    &--inline {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
    }

    /* Size modifiers */
    &--xsmall {
        .ImageThumbnail-sizeConstructor(@ImageThumbnail-size--xsmall);
    }

    &--small {
        .ImageThumbnail-sizeConstructor(@ImageThumbnail-size--small);
    }

    &--mid {
        .ImageThumbnail-sizeConstructor(@ImageThumbnail-size--mid);
    }

    &--large {
        .ImageThumbnail-sizeConstructor(@ImageThumbnail-size--large);
    }

    &--xlarge {
        .ImageThumbnail-sizeConstructor(@ImageThumbnail-size--xlarge);
    }

    &--fluid {
        height: auto;
        width: 100%;
    }

    /* Behavior modifiers */
    &--clickable,
    &-link {
        /* Adds a hover state to indicate it is clickable
        This is just so that we can apply the module on certain use cases
        where the module is in a link instead of having a &-link element inside. */
        .ImageThumbnail-innerShadowConstructor();
    }

    &--circle {
        border-radius: 50%;

        &::before {
            border-radius: 50%;
        }
    }

    &--online {
        border: 2px solid @quinary-color;
    }
}
