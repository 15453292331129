@import (reference) "libs/mixins.less";
@import (reference) "theme/variables.less";

/* Animations */
@import (reference) "mixins/animated/fadeIn.less";

.dropdown-nav {
    .animated(0.22s);
    .fadeIn();
    background-color: fade(@septenary-color-xxxlight, 98.5%);
    border: 1px solid @border-color-light;
    display: none;
    padding: @spacing-xsmall 0;
    position: absolute;
    text-align: left;
    width: 250px;
    z-index: @z-index-dropdown;
}

.dropdown-nav-item {
    .type(detail);
    color: @text-color-dark;
    cursor: pointer;
    display: block;
    letter-spacing: normal;
    padding: @spacing-xxsmall @spacing-small;
    position: relative;
    transition: all 0.1s ease-in;
    width: 100%;
    z-index: @z-index-dropdown;

    .promotion-tag {
        .type(detail);
        color: @secondary-color;
        display: inline-block;
        font-weight: bold;
        text-align: right;
        text-transform: uppercase;
        width: 30%;
    }

    &:hover {
        background: @septenary-color-xxlight;
        color: @text-color-dark;
        text-decoration: none;
        transition: all 0.05s ease-in;
    }
}

.dropdown-heading {
    background: @quaternary-color;
    color: @septenary-color-xxxlight;
    font-weight: bold;
    padding: @spacing-xxsmall;
}

.dropdown-menu {
    padding: 0;
}
