@import "../modules/select.less";
@import "../modules/expand-icon.less";

/*************************************************/
/* utilities */

.oneline { /* stop text in an input dropping down onto another line */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icon-left {
    position: relative;
    padding-left: 34px;
    [class*="fl-icon-"] {
        position: absolute;
        right: 15px;
        top: 16px;
    }
}

.icon-right {
    position: relative;
    padding-right: 34px;
    [class*="fl-icon-"] {
        position: absolute;
        right: 15px;
        top: 16px;
    }
}

input {
    &::-webkit-input-placeholder {
        color: @placeholder-text-color;
    }
    &:-moz-placeholder {
        color: @placeholder-text-color;
    }
    &::-moz-placeholder {
        color: @placeholder-text-color;
    }
    &:-ms-input-placeholder {
        color: @placeholder-text-color;
    }
}

.default-form-field {
    display: inline-block;
    width: 100%;
    height: 50px;
    padding: 4px 15px 0;
    border-style: solid;
    border-width: 1px;
    background-color: @input-bg;
    .type(ui);
    color: @input-text-color;
}

.small-form-field {
    .default-form-field;
    height: 38px;
    padding: 4px 9px 0;
}

.placeholder {
    padding-bottom: 10px;
}

.no-placeholder {
    .placeholder {
        display: inline-block;
    }
}

.input-focus-constructor(@color) {
    color: @color;
    border-color: @color;
    .box-shadow(~"0 0 4px @{color}");
}

/*************************************************/
/* form themes */

.default-field-skin {
    .border-radius(3px);
    border-color: @field-border-color;
    background-color: @site-bg-color;
    &:focus {
        .input-focus-constructor(@field-focus-color);
    };
    .error {
        &:focus {
            .input-focus-constructor(@alert-color);
        }
    }
}

.default-skin {
    [type="text"],
    [type="password"] {
        .default-field-skin;
    }
    .error {
        [type="text"],
        [type="password"] {
            border-color: @alert-color;
            color: @alert-color;
        }
    }
    .placeholder {
        font-weight: bold;
    }
}

/*************************************************/
/* search */

.search-input {
    position: relative;
    [type="search"],
    [type="text"] {
        vertical-align: top;
        width: 100%;
        height: 34px;
        padding: 0 14px 0 34px;
        line-height: 24px;
        .border-radius(17px, 17px, 17px, 17px);
        .box-shadow(none);
    }
    [class*="fl-icon-"] {
        position: absolute;
        left: 12px;
        top: 13px;
    }
}

.search-input-small {
    .search-input;
    .type(detail);
    [type="search"],
    [type="text"] {
        height: 23px;
        line-height: 23px;
        padding: 0 14px 0 22px;
        .border-radius(11px, 11px, 11px, 11px);
    }
    [class*="fl-icon-"] {
        position: absolute;
        left: 6px;
        top: 6px;
    }
}

.default-form {
    >fieldset {
        > ol {
            padding: 0;
            list-style: none;
        }
    }
    .form-step {
        margin-bottom: 20px;
    }
    [type="text"],
    [type="password"] {
        .default-form-field;
    }
    select {
        .default-select;
    }
}
