@import (reference) 'dropdown.less';
@import (reference) 'select.less';
@import (reference) 'inputs.less';

/* Animations */
@import (reference) "libs/mixins.less";

.large-form {
    font-size: 16px;

    [type="text"],
    [type="email"],
    [type="password"],
    [type="number"] {
        .large-input();
    }

    [type="checkbox"],
    [type="radio"] {
        margin-top: 2px;
    }

    select {
        .large-select();
    }

    textarea {
        .large-textarea();
    }

    .form-step {
        padding-bottom: 20px;

        .form-invalid-icon {
            margin-top: 1px;
            .type(detail);
        }

        .form-valid-icon {
            .type(ui);
        }

        .input-icon:before {
            font-size: 22px;
        }
    }

    .add-on {
        font-size: 16px;
    }

    .form-help {
        padding-top: 0;
    }

    &.has-numbers {
        > ol > li {
            &::before {
                top: -2px;
                width: 30px;
                height: 30px;
                .type(ui);
                line-height: 29px;
                font-weight: 900;
            }

            legend {
                margin-bottom: 22px;
                font-size: 22px;
            }
        }
    }

}
