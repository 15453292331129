/**
 * Announcement Bars
 *
 * A module used to present important messaging such as verifications, membership changes,
 * server downtimes etc.
 *
 * .AnnouncementBar--dark - A dark version of of this module
 * .is-AnnouncementBar-success - Communicate a saccessfull action
 * .is-AnnouncementBar-error - Communicate that something went wrong
 *
 * Markup: announcement-bar.html
 *
 * Styleguide: Alerts - Announcement Bars
 */

/**
 * 1. Required for allowing element to shrink in IE.
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.AnnouncementBar {
    @this: AnnouncementBar;

    @AnnouncementBar-successColor: @quinary-color-dark;
    @AnnouncementBar-infoColor: @primary-color-dark;
    @AnnouncementBar-errorColor: @senary-color-dark;
    @AnnouncementBar-darkColor: @quaternary-color-xxdark;

    background-color: @AnnouncementBar-infoColor;
    color: @text-color-light;
    padding: @spacing-xxsmall + @spacing-xsmall 0;

    * {
        .box-sizing(border-box);
    }

    &-inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: @breakpoint-mobile) {
            flex-direction: row;
        }
    }

    &-content {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
    }

    &-icon {
        align-items: center;
        background: fade(@septenary-color-xxxlight, 20%);
        border-radius: 50%;
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        margin-right: 12px;
        padding: 12px;

        [class*="flicon-"] {
            height: 24px;
            stroke: @septenary-color-xxxlight;
            width: 24px;
        }
    }

    &-copy {
        display: block;
        /* 1. */
        flex: 1 1 auto;

        a {
            color: @text-color-light;
            text-decoration: underline;

            &:hover {
                color: @text-color-light;
                text-decoration: none;
            }
        }
    }

    &-heading {
        .type(ui);
        font-weight: bold;
    }

    &-message {
        .type(ui);

        // FIXME: Refactor this to use ui-icon so it's unnecessary
        &-icon {
            display: inline-block;
            margin: 2px 0 0 @spacing-xxsmall;
            vertical-align: middle;

            > svg {
                height: 16px;
                stroke: @septenary-color-xxxlight;
                stroke-width: 2px;
                width: 16px;
            }
        }
    }

    &-buttons {
        display: flex;
    }

    &-control {
        background: transparent;
        border: none;
        cursor: pointer;
        flex: 0 0 auto;
        margin-left: @spacing-xsmall + @spacing-xxsmall;
        margin-top: @spacing-small;

        &:last-child {
            display: none;

            @media (min-width: @breakpoint-mobile) {
                display: block;
            }
        }

        @media (min-width: @breakpoint-mobile) {
            margin-top: 0;
        }

        .flicon-close {
            height: 12px;
            stroke: fade(@text-color-light, 80%);
            width: 12px;
        }

        &:hover {
            .flicon-close {
                stroke: @septenary-color-xxxlight;
            }
        }
    }

    &--dark {
        background-color: @AnnouncementBar-darkColor;
    }

    &--nubelo {
        background-color: @nubelo-color;
    }

    &.is-@{this}-success {
        background-color: @AnnouncementBar-successColor;
    }

    &.is-@{this}-error {
        background-color: @AnnouncementBar-errorColor;
    }
}
