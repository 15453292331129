/**
 * source: hint-color-types.less
 *
 * Contains tooltips of various types based on color differences.
 *
 * Classes added:
 *  1) hint--light
 *
 */

@import (reference) "theme/variables.less";
@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

/* mixin to generate different color style tooltips */
.hint-type(@color) {
  &:after {
    background-color: @color;
  }

  /* generate arrow color style */
  .arrow-border-color(@color);
}

/**
 * Light
 */
.@{hintPrefix}light {
  .hint-type(@septenary-color-xxlight);

  &::after {
    color: @text-color-dark;
  }
}
