@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "flux/layout/LoginSection.less";

@primary-nav-height: 64px;
@fl-logo-width: 145px;
@secondary-nav-height: @spacing-xlarge;
@secondary-nav-dropdown-column-width: 210px;
@secondary-nav-bottomLine-height: 3px;
@mobile-navbar-breakpoint: @breakpoint-desktop-small;
@mobile-navbar-anchorIcon-size: 28px;

.NavigationLoggedOut {
    @this: NavigationLoggedOut;

    &-primary {
        .box-shadow-level-1();
        position: relative;

        &-content {
            .site-section-inner();
            align-items: center;
            background: @septenary-color-xxxlight;
            display: flex;
            flex-wrap: wrap;
            min-height: @primary-nav-height;
            position: relative;
            z-index: 1;
        }

        &--arrow {
            background: @septenary-color-xxxdark;
            color: @septenary-color-xxxlight;

            .primary-navigation-section {
                background: @septenary-color-xxxdark;
            }
        }

        &-signupBtn {
            display: none;

            @media (min-width: @breakpoint-mobile) {
                display: block;
            }
        }

        &-mobileNavbar {
            cursor: pointer;

            &-toggleControl {
                display: none;
            }

            &-toggle {
                display: block;

                @media (min-width: @mobile-navbar-breakpoint) {
                    display: none;
                }
            }

            &-toggle > span {
                background: @septenary-color-xxxdark;
                display: block;
                height: 4px;
                opacity: 1;
                position: relative;
                transform-origin: 4px 0px;
                transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                    opacity 0.55s ease;
                width: 33px;
                z-index: 1;

                &:first-child {
                    transform-origin: 0% 0%;
                }

                &:nth-last-child(2) {
                    transform-origin: 0% 100%;
                }

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }

            &-toggleControl:checked ~ .@{this}-primary {
                & .@{this}-primary-mobileNavbar-toggle {
                    & > span {
                        background: @septenary-color-xxxdark;
                        opacity: 1;
                        transform: rotate(45deg) translate(-2px, -1px);
                    }

                    & > span:nth-child(2) {
                        opacity: 0;
                        transform: rotate(0deg) scale(0.2, 0.2);
                    }

                    & > span:nth-child(3) {
                        transform: rotate(-45deg) translate(-4px, -3px);
                    }
                }

                // Show the mobile navigation container.
                & .@{this}-primary-mobileNavbar-container {
                    display: block;

                    @media (min-width: @mobile-navbar-breakpoint) {
                        display: none;
                    }
                }
            }

            &-container {
                background-color: @bg-color-light;
                display: none;
                left: 0;
                margin: auto -@site-section-padding;
                min-height: ~"calc(100vh - @{primary-nav-height})";
                order: 2;
                padding: @spacing-large;
                scroll-behavior: smooth;
                top: @primary-nav-height;
                width: 100vw;
                z-index: 1;

                &-divider {
                    background: @septenary-color-xxlight;
                    height: 1px;
                    margin: @spacing-small -@spacing-large;
                }
            }

            .@{this} &-signupBtn {
                display: flex;

                @media (min-width: @breakpoint-mobile) {
                    display: none;
                }
            }

            &-postBtn {
                margin-bottom: @spacing-xsmall;
                width: 100%;
            }

            &-link {
                align-items: center;
                color: @text-color-dark;
                display: flex;
                fill: @text-color-dark;
                min-height: 28px;
                padding: @spacing-small 0;
                text-decoration: none;

                &:hover > * {
                    color: @primary-color-dark;
                    fill: @primary-color-dark;
                }

                &-title {
                    color: @text-color-dark;
                    line-height: 28px;
                    margin-left: @spacing-small;
                    text-decoration: none;
                    vertical-align: middle;
                }

                &-icon {
                    height: 20px;
                    margin: @spacing-xxsmall;
                    width: 20px;
                }

                &-anchorIcon {
                    align-items: center;
                    display: flex;
                    fill: @septenary-color-dark;
                    margin-left: auto;

                    &--alignLeft {
                        margin-left: 0;
                    }

                    .Icon-image {
                        height: @mobile-navbar-anchorIcon-size;
                        width: @mobile-navbar-anchorIcon-size;
                    }
                }

                &--offsetLeft {
                    margin-left: @mobile-navbar-anchorIcon-size;
                }
            }

            &-menuControl {
                display: none;

                &:checked + .@{this}-primary-mobileNavbar-menu {
                    display: block;
                }
            }

            &-menu {
                display: none;
            }
        }

        &-logoContainer {
            display: inline-block;
            line-height: @primary-nav-height;
            margin-left: @spacing-xsmall;
            transition: all 0.2s ease-in;

            @media (min-width: @breakpoint-mobile) {
                margin-left: @spacing-small;
            }

            .flicon-logo-fullcolor {
                height: auto;
                stroke-width: 0;
                vertical-align: middle;
                width: 120px;

                @media (min-width: @breakpoint-mobile) {
                    width: @fl-logo-width;
                }

                &--arrow {
                    width: 267px;
                }
            }

            @media (min-width: @mobile-navbar-breakpoint) {
                margin: 0;
            }
        }

        &-navItems {
            align-self: center;
            display: none;

            &-link {
                .type(landing);
                color: @text-color-dark;
                letter-spacing: -0.024em;
                margin: 0 @spacing-small;
                position: relative;

                &:hover {
                    color: @primary-color-dark;
                    text-decoration: none;
                }
            }

            @media (min-width: @breakpoint-desktop-small) {
                display: flex;
            }
        }

        &-loginSection {
            align-items: center;
            display: flex;
            margin-left: auto;

            &-btnPost {
                display: none;

                @media (min-width: @mobile-navbar-breakpoint) {
                    display: block;
                }
            }
        }
    }

    &-secondary {
        background: @quaternary-color-xxxxxlight;
        display: none;

        @media (min-width: @mobile-navbar-breakpoint) {
            display: block;
        }

        &-navItems {
            .site-section-inner();
            align-items: stretch;
            display: flex;
        }

        &-navItem {
            align-items: center;
            display: flex;
            margin-right: @spacing-large;
            min-height: @secondary-nav-height;
            padding: 0 @spacing-small;
            position: relative;

            &:hover {
                border-bottom: @secondary-nav-bottomLine-height solid @primary-color-light;
                padding-top: @secondary-nav-bottomLine-height;
            }

            &-name {
                .type(landing);
                color: @text-color-dark;
                cursor: pointer;
                letter-spacing: -0.024em;

                &:hover {
                    color: @primary-color-dark;
                    text-decoration: none;
                }
            }

            &-dropdown {
                .type(detail);
                .box-shadow-level-02();
                background-color: @bg-color-light;
                display: flex;
                flex-wrap: nowrap;
                left: 0;
                padding: @spacing-mid 0;
                position: absolute;
                top: @secondary-nav-height;
                z-index: 2;

                &-menuWrapper {
                    display: none;
                    min-width: @secondary-nav-dropdown-column-width;
                    position: relative;

                    // Separator between sub-menu.
                    &:not(:first-child)::before {
                        border-left: 1px solid @quaternary-color-xxxlight;
                        content: "";
                        height: 100%;
                        left: -1px; // Offset the 1px in the border.
                        padding: @spacing-mid 0;
                        position: absolute;
                    }

                    &.is-active {
                        display: block;
                    }
                }

                &-link {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    line-height: 1.6;
                    padding: @spacing-xxsmall @spacing-mid;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    & > * {
                        color: @septenary-color-xxxdark;
                        cursor: pointer;
                        text-decoration: none;
                    }

                    &:hover > *,
                    &.is-active > * {
                        color: @primary-color-dark;
                        fill: @primary-color-dark;
                    }

                    &-anchorIcon {
                        fill: @septenary-color-light;

                        .Icon-image {
                            margin-right: -@spacing-xsmall;
                        }
                    }
                }
            }

            // Display the dropdown menu when the user hover on any part of the navbar.
            &:hover {
                .@{this}-secondary-navItem-dropdown-menuWrapper:first-child {
                    display: block;
                }
            }
        }
    }
}
