//
// Responsive Modal
// --------------------------------------------------
/*
    Based on the bootstrap 3 responsive modal, optimised for touch
    Requirements :
    - a class of modal-open appended body element when the modal is opened
    - regular BS modal must be wrapped in a element with a class of .responsive-modal
*/
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (multiple) "mixins/animated/fadeIn.less";


body.modal-open {
    overflow: hidden;
}

.responsive-modal {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    .modal {
        position: relative;
        top: auto;
        bottom: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .modal-body {
        overflow-y: visible;
        max-height: none;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
    overflow-y: auto;
    background-color: @overlay-color;
    .animated(.35s);
    .fadeIn();
}

@media only screen and(max-width : 600px) {
    .responsive-modal {
        .modal {
            left: 0;
            width: auto;
            margin: 10px auto;
        }
    }
}

@media only screen and(max-width : 480px) {
    .responsive-modal {
        .modal-body {
           padding: 15px !important;
        }
    }
}
