/**
 * Select
 *
 * The standard select element
 *
 * .default-select - Default button
 * .small-select - Small variation
 * .large-select    - Large variation
 * .xlarge-select - Extra Large variation
 *
 * Markup: default-select.html
 *
 * Weight: -999
 *
 * Styleguide: Forms - Elements - Select
 */

@import (reference) "dropdown.less";
@import (reference, multiple) "inputs.less";

/* 1. fix to vertically align select text in Mozilla */

.default-select {
    .default-input();
}

.small-select {
    .small-input();
}

.large-select {
    .large-input();
}

.xlarge-select {
    .xlarge-input();
    .border-radius(5px, 5px, 5px, 5px);
}

body:not(:-moz-handler-blocked) {
    .default-select,
    .small-select,
    .large-select,
    .xlarge-select {
        padding-top: 0.6em !important;
        padding-bottom: 0.6em !important;
    }
}

.small-select,
.default-select,
.large-select,
.xlarge-select {
    padding-right: 0;
}
