/**
 * source: hint-show.less
 *
 * Defines a persisted tooltip which shows or toggle it when hint--noHover is used.
 *
 * Classes added:
 *   1) hint--show
 *
 */

@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

.@{hintPrefix}show {
  &::after,
  &::before,
  &:hover::after,
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }

  &.@{hintPrefix}top {
    .set-margin(translateY, -1, -50%);

    &-left {
      .set-margin(translateY, -1, -100%);
    }
    &-right {
      .set-margin(translateY, -1, 0);
    }
  }

  &.@{hintPrefix}bottom {
    .set-margin(translateY, 1, -50%);
  }

  &.@{hintPrefix}left {
    .set-margin(translateX, -1);
  }

  &.@{hintPrefix}right {
    .set-margin(translateX, 1);
  }
}
