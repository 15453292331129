/**
 * Card
 *
 * The basic card module
 *
 * .Card--simpleHeader - Header without seperating border.
 * .Card--simpleFooter - Footer without seperating border.
 *
 * Markup: card.html
 *
 * Styleguide: Cards - Card
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.Card {
    @this: Card;

    @Card-horizontalGutter: 24px;
    @Card-verticalSpacing: 24px;

    .card();

    &-header,
    &-footer {
        margin: 0 @Card-horizontalGutter;
    }

    &-header {
        padding: @Card-verticalSpacing 0 @Card-verticalSpacing/2 0;
        border-bottom: 1px solid @border-color-light;
    }

    &-heading {
        margin: 0;
        .type(heading3);
        font-weight: bold;
    }

    &-body {
        padding: @Card-verticalSpacing @Card-horizontalGutter;
    }

    &-footer {
        padding: @Card-verticalSpacing/2 0 @Card-verticalSpacing 0;
        border-top: 1px solid @border-color-light;
    }

    &--simpleHeader {
        .@{this}-header {
            padding-bottom: 0;
            border: 0;
        }
    }

    &--simpleFooter {
        .@{this}-footer {
            padding-top: 0;
            border: 0;
        }
    }
}
