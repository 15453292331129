/**
 * source: hint-noHover.less
 *
 * Defines a tooltip that doesn't show on hover to use with programmatically
 * showing/hiding via JS.
 *
 * Classes added:
 *   1) hint--noHover
 *
 */

@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

.@{hintPrefix}noHover {
    &:hover::before,
    &:hover::after {
        visibility: hidden;
        opacity: 0;
    }
}
