/* Login Section */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.LoginSection {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;

    &-btn {
        .type(landing);
        border: 0;
        color: @text-color-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @spacing-xsmall;

        &:hover {
            color: @link-color-hover;
        }

        @media (min-width: @breakpoint-mobile) {
            padding: 0 @spacing-small;
        }

        &--arrow {
            color: @text-color-light;

            &:hover {
                color: @text-color-light;
            }
        }
    }

    &-btnPost {
        .type(landing);
        display: none;
        margin-left: @spacing-small;

        @media (min-width: @breakpoint-tablet) {
            display: inline-block;
        }
    }

    &-textToPost {
        display: none;
        margin-left: @spacing-xsmall;
        text-align: center;
        vertical-align: middle;

        @media (min-width: @breakpoint-tablet) {
            display: inline-block;
            min-width: 151px;
        }

        &-label {
            .type(tiny);
        }

        &-contact {
            .type(ui);
        }

        &--transparent {
            color: @text-color-light;
        }
    }
}
