@import (reference) "theme/variables.less";

.feedback {
    &-btn {
        margin-bottom: 30px !important;
    }

    &-heading {
        margin-bottom: 40px!important;
    }

    &-status {
        margin: 4px;
        padding: 20px;
        background-color: @quaternary-color-xxxxxlight;
        border: 1px solid @border-color-light;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px @septenary-color-xxlight;
        text-align: center;
    }
}
