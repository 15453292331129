/**
 * Forms
 *
 * Here is an example form with the validations.
 *
 * Markup: form-mockup.html
 *
 * Styleguide: Forms
 */

/**
 * Form Elements
 *
 * Styleguide: Forms - Elements
 */

/* 1. set the gutter width for semantic grid to 0 for just inside the forms */

/**
 * Radio and checkbox - DEPRECATED
 *
 * **Deprecated**
 *
 * Markup: checkboxes-radios.html
 *
 * Styleguide: Deprecated - Forms
 */

@import (reference) "dropdown.less";
@import (reference) "select.less";
@import (reference) "inputs.less";

/* Animations */
@import (reference) "libs/mixins.less";
@import "mixins/animated/fadeInUp.less";
@import "mixins/animated/fadeInDown.less";

@gutter-width: 0; /* 1. */

/* component for fieldset number */
.circled-number() {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: @septenary-color-xxxlight;
    background: @primary-color-light;
}

/*************************************************/
/* field states */

.input-valid-state() {
    label,
    [class*="fl-icon-"] {
        color: @field-valid-color;
    }

    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    [type="tel"],
    textarea,
    select {
        border-color: @field-valid-color;
        color: @field-valid-color;

        &:focus {
            .input-focus-constructor(@field-valid-color);
        }
    }
}

.input-error-state() {
    label,
    [class*="fl-icon-"] {
        color: @field-error-color;
    }

    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    [type="tel"],
    textarea,
    select {
        border-color: @field-error-color;
        color: @field-error-color;

        &:focus {
            .input-focus-constructor(@field-error-color);
        }
    }

    ::-webkit-input-placeholder {
        color: @field-error-color;
    }

    ::-moz-placeholder {
        color: @field-error-color;
    }

    :-ms-input-placeholder {
        color: @field-error-color !important;
    }
}

.input-help-state() {
    label,
    [class*="fl-icon-"] {
        color: @field-help-color;
    }

    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    [type="tel"],
    textarea,
    select {
        border-color: @field-help-color;
        color: @field-help-color;

        &:focus {
            .input-focus-constructor(@field-help-color);
        }
    }

    ::-webkit-input-placeholder {
        color: @field-help-color;
    }

    ::-moz-placeholder {
        color: @field-help-color;
    }

    :-ms-input-placeholder {
        color: @field-help-color !important;
    }
}

/**
 * Form Types
 *
 * Styleguide: Forms - Types
 */

/**
 * Default form and variations
 *
 * The standard form used on the website. Always use form-step for wrapping
 * label, form error and the input. Validation is dictated on form-step,
 * is-valid/ is-invalid mutator classes belong to form-step class.
 *
 * .is-simple-form - The simple form hides the labels and reduces the gap between form steps.
 * .has-numbers - Adds numbers to each fieldset
 * .small-form - A small version of the form.
 * .large-form - A large version of the form.
 *
 * Markup: default-form.html
 *
 * Weight: -999
 *
 * Styleguide: Forms - Types - Default
 */

/**
 * Default form /w numbers
 *
 * A submodule of the default form, adds numbers to each fieldset.
 *
 * Markup: default-form-with-numbers.html
 *
 * Styleguide: Forms - Types - With Numbers
 */

.fl-form {
    &.is-simple-form {
        fieldset {
            margin-bottom: 40px;
        }

        legend {
            margin-bottom: 10px;
            border-bottom: none;

            [class*="fl-icon-"] {
                margin-right: 10px;
            }
        }

        .form-step {
            padding-bottom: 16px !important;

            > label:first-child {
                display: none;
            }

            &.has-label {
                label:first-child {
                    display: block;
                }
            }
        }

        ::-ms-input-placeholder {
            color: @quaternary-color-xlight !important;
        }

        ::-webkit-input-placeholder {
            color: @septenary-color;
        }

        ::-moz-placeholder {
            color: @quaternary-color-xlight;
        }

        :-ms-input-placeholder {
            color: @quaternary-color-xlight !important;
        }
    }

    legend {
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 300;
    }

    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    fieldset {
        position: relative;
        margin: 0 0 50px;

        > ol {
            list-style: none;
        }
    }

    &.has-numbers {
        > ol {
            margin: 0;
            padding: 0;
            list-style-type: decimal;

            > li {
                position: relative;
                list-style: none;
                counter-increment: li;

                &::before {
                    position: absolute;
                    top: -3px;
                    left: 0px;
                    content: counter(li);
                    .circled-number();
                }

                &.is-submit::before {
                    display: none;
                }

                ol {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
            }

            legend {
                padding-left: 40px;
                border: none;
            }

            .is-submit,
            .is-accept {
                &::before {
                    display: none;
                }
            }
        }
    }

    /* 1. accept label still still show even for simple forms */

    .is-accept label {
        display: block !important; /* 1. */
    }

    .form-accept {
        position: relative;
        margin-top: 5px;
        padding-left: 30px;
        font-weight: normal;
        cursor: pointer;

        input[type="checkbox"] {
            position: absolute;
            top: 3px;
            left: 3px;
        }
    }

    /*  1. inputs fade in in sequence - we shouldn't need to delay more than 9 as we wouldn't be able to show more on the page at once
    */

    .form-step { /* 2 */
        position: relative;
        margin-bottom: 0 !important;
        padding-bottom: 20px;
        .animated(.4s);
        .fadeIn;

        &.has-inline {
            label:not(:first-child) {
                display: inline-block;
                margin-right: 30px;
            }
        }

        &:last-child &:not(.is-hidden) {
            margin: 0;
        }

        /* 4 */
        &.is-valid {
            .input-valid-state();

            .form-error {
                display: none;
            }

            .input-icon::before {
                color: @field-valid-color;
            }
        }

        &.is-invalid {
            .input-error-state();

            .form-error {
                .animated(.4s);
                .fadeInDown;
                display: block;
            }

            .input-icon::before {
                color: @field-error-color;
            }
        }

        &.is-help {
            .form-help {
                .animated(.4s);
                .fadeInDown();
                display: block;
            }
        }
    }

    .form-step--multiFields {
        display: table;
        width: 100%;
    }

    .form-step-fieldContainer {
        display: table-cell;

        &:not(:first-child) {
            padding-left: 10px;
        }
    }

    /* 1. Don't bold checkbox/radio labels etc */

    label {
        display: block;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: bold;

        & + label { /* 1. */
            font-weight: normal;
        }

        &.btn {
            display: inline-block;
        }
    }

    .checkbox {
        font-weight: normal;
    }

    [type="checkbox"],
    [type="radio"] {
        margin-top: 1px;
        margin-right: 10px;
        vertical-align: top;
    }

    .input-group {
        display: table !important;
        position: relative;
        width: 100%;
        border-collapse: separate;

        > input,
        > select {
            display: table-cell;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            & + .add-on {
                border-top-left-radius: 0;
                border-top-right-radius: @form-field-border-radius;
                border-bottom-right-radius: @form-field-border-radius;
                border-bottom-left-radius: 0;
                border-left: none;
            }
        }

        .add-on {
            display: table-cell;
            padding: 9px 12px;
            border: 1px solid @field-border-color;
            border-top-left-radius: @form-field-border-radius;
            border-bottom-left-radius: @form-field-border-radius;
            line-height: 1;
            text-align: center;
            vertical-align: middle;
            color: @septenary-color;
            background-color: @septenary-color-xxlight;

            &:first-child {
                border-right: 0;
            }

            & + input,
            & + select {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &--rightRoundedCorner {
                & + input {
                    border-top-right-radius: @border-radius;
                    border-bottom-right-radius: @border-radius;
                }
            }
        }

    }

    /* form help */

    .form-help {
        .text-wrap();
        display: none;
        position: relative;
        min-height: 20px;
        margin-top: 10px;
        padding-top: 1px;
        padding-left: 37px;
        font-style: italic;
        line-height: 16px;
        color: @primary-color;

        &::before {
            .type(ui);
            position: absolute;
            top: -1px;
            left: 9px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            font-style: normal;
            line-height: 20px;
            font-weight: bold;
            text-align: center;
            color: @text-color-light;
            background: @primary-color;
            content: "?";
        }
    }

    /* form input help */

    .form-success {
        display: none;
        margin: 5px 0;
        color: @field-valid-color;
    }

    .form-warning {
        display: none;
        margin: 5px 0;
        color: @field-warning-color;
    }

    .form-error {
        display: none;
        margin: 5px 0;
        color: @field-error-color;
    }

    .form-conditions {
        display: block;
        margin-bottom: 20px;
    }

    /* used on feedback result of form */
    .form-feedback-success {
        border: 1px solid @success-notify-color;
        color: @success-notify-content-color;
        background-color: @success-notify-color-light;
    }

    .form-feedback-error {
        border: 1px solid @error-notify-color;
        color: @error-notify-content-color;
        background-color: @error-notify-color-light;
    }

    .form-feedback-error,
    .form-feedback-success {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: @border-radius;
        font-weight: bold;
    }
}
