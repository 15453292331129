/*************************************************/
/* loading icon */

/* Requirements
    - our icomoon icon set to be loaded on the page
    - parent container to have relative positioning if you wish to use the position data-attr
*/

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.dark-fallback-gif {
    background-image: url("/static/css/images/ajax-loader-med-dark.gif");
}

.loading-icon {
    z-index: 99;
    display: inline-block;
    .default-size;
    .default-theme;

    /* POSITIONING */
    &[data-alignment="center"] {
        position: absolute;
        left: 50%;
        top: 50%;
    }
    /* SIZES */
    &.is-mini {
        .type(detail);
    }
    &[data-size="small"] {
        font-size: 16px;
        &[data-alignment="center"] {
            margin-left: -10px;
            margin-top: -10px;
        }
    }

    /* Medium size */
    &[data-size="med"],
    .default-size {
        font-size: 20px;
        &[data-alignment="center"] {
            margin-left: -10px;
            margin-top: -10px;
        }
    }

    /* Large size */
    &[data-size="large"] {
        font-size: 36px;
        &[data-alignment="center"] {
            margin-left: -18px !important;
            margin-top: -18px !important;
        }
    }

    /* THEME */
    /* Light */
    &[data-theme="light"],
    .default-theme {
        color: @quaternary-color-xxxlight;
    }

    /* Dark */
    &[data-theme="dark"] {
        color: @quaternary-color-dark;
    }

    [class*="fl-icon-"] {
        height: 1em;
        line-height: 1;
        display: inline-block;
        animation: Rotate 1.5s infinite linear;
        -webkit-animation: Rotate 1.5s infinite linear;
        @-o-keyframes Rotate {
            0% {
                -o-transform: rotate(0deg);
                opacity: 1;
            }
            50% {
                opacity: .5;
            }
            100% {
                -o-transform: rotate(360deg);
                opacity: 1;
            }
        }
        @-moz-keyframes Rotate {
            0% {
                -moz-transform: rotate(0deg);
                opacity: 1;
            }
            50% {
                opacity: .5;
            }
            100% {
                -moz-transform: rotate(360deg);
                opacity: 1;
            }
        }
        @-ms-keyframes Rotate {
            0% {
                -ms-transform: rotate(0deg);
                opacity: 1;
            }
            50% {
                opacity: .5;
            }
            100% {
                -ms-transform: rotate(360deg);
                opacity: 1;
            }
        }
        @-webkit-keyframes Rotate {
            0% {
                -webkit-transform: rotate(0deg);
                opacity: 1;
            }
            50% {
                opacity: .5;
            }
            100% {
                -webkit-transform: rotate(360deg);
                opacity: 1;
            }
        }
    }
    .no-cssanimations & {
        width: 20px;
        height: 20px;
        padding: 3px;
        background-image: url("/static/css/images/ajax-loader-med-light.gif");
        background-size: 100%;
        [class*="fl-icon-"] {
            display: none;
        }
    }
}
