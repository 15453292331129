/**
 * Responsive Embed
 *
 * Allows video dimensions to properly scale on mobile devices
 * by using an intrinsic ratio.
 *
 * (Credit: Nicolas Gallagher, SUIT CSS and Bootstrap)
 *
 * .ResponsiveEmbed--21by9  - 21:9 aspect ratio
 * .ResponsiveEmbed--16by9  - 16:9 aspect ratio
 * .ResponsiveEmbed--4by3   - 4:3 aspect ratio
 * .ResponsiveEmbed--1by1   - 1:1 aspect ratio
 *
 * Markup: responsive-embed.html
 *
 * Styleguide: Responsive Embed
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.ResponsiveEmbed {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    &-item,
    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &--21by9 {
        padding-bottom: percentage(9 / 21);
    }

    &--16by9 {
        padding-bottom: percentage(9 / 16);
    }

    &--4by3 {
        padding-bottom: percentage(3 / 4);
    }

    &--1by1 {
        padding-bottom: percentage(1 / 1);
    }
}
