@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.Breadcrumbs {
    @this: Breadcrumbs;

    .type(ui);
    color: @text-color-mid;
    margin-bottom: @spacing-small;
    word-break: break-word;
    word-wrap: break-word;

    &-list {
        .list-unstyled();
    }

    &-item {
        display: inline-block;
        margin-left: @spacing-xsmall;

        &::after {
            content: "\203A";
            display: inline-block;
            margin-left: @spacing-xsmall;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    // Modifier to remove the last '/' in last item
    // TODO: Should be the default, but some pages don't have the modifier added
    &.@{this}--untrailed .@{this}-item:last-child::after {
        content: none;
    }

    .@{this}-link {
        color: inherit;

        &--last {
            display: inline-block;
            margin-left: @spacing-xsmall;
            text-decoration: none;
        }
    }

    [class*="flicon"] {
        fill: currentColor;
        margin-top: -@spacing-xxsmall;
        stroke: transparent;
        vertical-align: middle;
    }

    &--alignCenter {
        text-align: center;
    }
}
