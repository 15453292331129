/**
 * Earnings
 *
 * Used to display the user's earning rating on the site
 *
 * Markup: earnings.html
 *
 * Styleguide: Ratings - Earnings
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

@Earnings-width: 59px;
@Earnings-height: 8px;

.Earnings {
    @this: Earnings;

    align-items: center;
    display: inline-flex;
    position: relative;

    &-label {
        .type(ui);
        margin-right: @spacing-xsmall;
    }

    &-icon {
        background: url("/static/css/images/earnings/earnings-icon-base.svg") no-repeat;
        height: 13px;
        margin-right: @spacing-xxsmall;
        width: 8px;
    }

    &-total {
        background: url("/static/css/images/earnings/earnings-base.svg") 0 0 ~"/" @Earnings-width @Earnings-height no-repeat;
        height: @Earnings-height;
        position: relative;
        width: @Earnings-width;
    }

    &-progress {
        background: url("/static/css/images/earnings/earnings-fill.svg") 0 0 ~"/" @Earnings-width @Earnings-height no-repeat;
        height: inherit;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
    }

    /* Sets the icon into its active color if user's earning has a rating */
    &:not([data-user_earnings="0.0"]):not([data-user_earnings="0"]) {
        .@{this}-icon {
            background-image: url("/static/css/images/earnings/earnings-icon-fill.svg");
        }
    }
}
