/**
 * source: hint-theme.less
 *
 * Defines basic theme for tooltips.
 *
 */

@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

[@{hintDataAttribute}] {
  /**
   * tooltip body
   */
  &:after {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  }
}
