@font-face {
    font-family: 'fl-icon';
    src:url('/static/css/icons/fonts/fl-icon.eot');
    src:url('/static/css/icons/fonts/fl-icon.eot?#iefix') format('embedded-opentype'),
        url('/static/css/icons/fonts/fl-icon.woff2') format('woff2'),
        url('/static/css/icons/fonts/fl-icon.woff') format('woff'),
        url('/static/css/icons/fonts/fl-icon.ttf') format('truetype'),
        url('/static/css/icons/fonts/fl-icon.svg#fl-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="fl-icon-"], [class*=" fl-icon-"] {
    font-family: 'fl-icon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fl-icon-verified-alt:before {
    content: "\e6bf";
}
.fl-icon-shortlist:before {
    content: "\e6be";
}
.fl-icon-contest:before {
    content: "\e630";
}
.fl-icon-service:before {
    content: "\e689";
}
.fl-icon-certifications:before {
    content: "\e62c";
}
.fl-icon-publication:before {
    content: "\e62e";
}
.fl-icon-education:before {
    content: "\e603";
}
.fl-icon-experience:before {
    content: "\e60e";
}
.fl-icon-reviews:before {
    content: "\e61b";
}
.fl-icon-project:before {
    content: "\e61d";
}
.fl-icon-skills:before {
    content: "\e61f";
}
.fl-icon-fivestars:before {
    content: "\e600";
}
.fl-icon-pencil:before {
    content: "\e6bd";
}
.fl-icon-play:before {
    content: "\e659";
}
.fl-icon-film:before {
    content: "\e65a";
}
.fl-icon-coin:before {
    content: "\e65b";
}
.fl-icon-spinner:before {
    content: "\e65c";
}
.fl-icon-spinner-alt:before {
    content: "\e65d";
}
.fl-icon-expand:before {
    content: "\e65e";
}
.fl-icon-contract:before {
    content: "\e65f";
}
.fl-icon-expand2:before {
    content: "\e660";
}
.fl-icon-contract2:before {
    content: "\e661";
}
.fl-icon-signup:before {
    content: "\e662";
}
.fl-icon-earth:before {
    content: "\e663";
}
.fl-icon-star:before {
    content: "\e664";
}
.fl-icon-star-half:before {
    content: "\e665";
}
.fl-icon-star-full:before {
    content: "\e666";
}
.fl-icon-remove:before {
    content: "\e667";
}
.fl-icon-close:before {
    content: "\e668";
}
.fl-icon-checkmark:before {
    content: "\e669";
}
.fl-icon-minus2:before {
    content: "\e66a";
}
.fl-icon-arrow-up-right:before {
    content: "\e66b";
}
.fl-icon-arrow-down-left:before {
    content: "\e66c";
}
.fl-icon-feed:before {
    content: "\e67c";
}
.fl-icon-twitter:before {
    content: "\e67f";
}
.fl-icon-checkin2:before {
    content: "\e6a7";
}
.fl-icon-cash:before {
    content: "\e6a9";
}
.fl-icon-trafficlight:before {
    content: "\e6a8";
}
.fl-icon-colocation2:before {
    content: "\e6a4";
}
.fl-icon-comment6:before {
    content: "\e6a6";
}
.fl-icon-infographic:before {
    content: "\e6aa";
}
.fl-icon-menu:before {
    content: "\e6a5";
}
.fl-icon-earningsbar:before {
    content: "\e6b7";
}
.fl-icon-lock2:before {
    content: "\e6b4";
}
.fl-icon-marker:before {
    content: "\e6b5";
}
.fl-icon-sealed:before {
    content: "\e6b6";
}
.fl-icon-certificate:before {
    content: "\f0a3";
}
.fl-icon-chevron-sign-up:before {
    content: "\f139";
}
.fl-icon-paper:before {
    content: "\e6af";
}
.fl-icon-mobile:before {
    content: "\e6b0";
}
.fl-icon-laptop:before {
    content: "\e6b1";
}
.fl-icon-globe:before {
    content: "\e6b2";
}
.fl-icon-bulb:before {
    content: "\e6b3";
}
.fl-icon-circled-arrow:before {
    content: "\e6ae";
}
.fl-icon-logo-timesonline:before {
    content: "\e653";
}
.fl-icon-logo-forbes:before {
    content: "\e68b";
}
.fl-icon-envelope:before {
    content: "\e601";
}
.fl-icon-heart:before {
    content: "\e602";
}
.fl-icon-th:before {
    content: "\e604";
}
.fl-icon-th-list:before {
    content: "\e605";
}
.fl-icon-inbox:before {
    content: "\e606";
}
.fl-icon-tags:before {
    content: "\e607";
}
.fl-icon-tag:before {
    content: "\e608";
}
.fl-icon-time:before {
    content: "\e609";
}
.fl-icon-qrcode:before {
    content: "\e60a";
}
.fl-icon-volume-down:before {
    content: "\e60c";
}
.fl-icon-volume-off:before {
    content: "\e60d";
}
.fl-icon-lock:before {
    content: "\e60f";
}
.fl-icon-list-alt:before {
    content: "\e610";
}
.fl-icon-refresh:before {
    content: "\e611";
}
.fl-icon-repeat:before {
    content: "\e612";
}
.fl-icon-play-circle:before {
    content: "\e613";
}
.fl-icon-zoom-in:before {
    content: "\e614";
}
.fl-icon-zoom-out:before {
    content: "\e615";
}
.fl-icon-off:before {
    content: "\e616";
}
.fl-icon-book:before {
    content: "\e617";
}
.fl-icon-bookmark:before {
    content: "\e618";
}
.fl-icon-print:before {
    content: "\e619";
}
.fl-icon-facetime-video:before {
    content: "\e61a";
}
.fl-icon-map-marker:before {
    content: "\e61c";
}
.fl-icon-edit:before {
    content: "\e61e";
}
.fl-icon-chevron-right:before {
    content: "\e620";
}
.fl-icon-plus-sign:before {
    content: "\e621";
}
.fl-icon-remove-sign:before {
    content: "\e623";
}
.fl-icon-ok-sign:before {
    content: "\e624";
}
.fl-icon-plus:before {
    content: "\e625";
}
.fl-icon-resize-small:before {
    content: "\e626";
}
.fl-icon-resize-full:before {
    content: "\e627";
}
.fl-icon-share-alt:before {
    content: "\e628";
}
.fl-icon-info-sign:before {
    content: "\e629";
}
.fl-icon-screenshot:before {
    content: "\e62a";
}
.fl-icon-remove-circle:before {
    content: "\e62b";
}
.fl-icon-ban-circle:before {
    content: "\e62d";
}
.fl-icon-arrow-right:before {
    content: "\e62f";
}
.fl-icon-arrow-down:before {
    content: "\e631";
}
.fl-icon-question-sign:before {
    content: "\e632";
}
.fl-icon-gift:before {
    content: "\e633";
}
.fl-icon-exclamation-sign:before {
    content: "\e634";
}
.fl-icon-asterisk:before {
    content: "\e635";
}
.fl-icon-minus:before {
    content: "\e636";
}
.fl-icon-eye-open:before {
    content: "\e637";
}
.fl-icon-eye-close:before {
    content: "\e638";
}
.fl-icon-warning-sign:before {
    content: "\e639";
}
.fl-icon-calendar:before {
    content: "\e63a";
}
.fl-icon-comment:before {
    content: "\e63b";
}
.fl-icon-chevron-down:before {
    content: "\e63c";
}
.fl-icon-retweet:before {
    content: "\e63d";
}
.fl-icon-shopping-cart:before {
    content: "\e63e";
}
.fl-icon-folder-close:before {
    content: "\e63f";
}
.fl-icon-folder-open:before {
    content: "\e640";
}
.fl-icon-resize-vertical:before {
    content: "\e641";
}
.fl-icon-chevron-up:before {
    content: "\e642";
}
.fl-icon-resize-horizontal:before {
    content: "\e643";
}
.fl-icon-heart-empty:before {
    content: "\e644";
}
.fl-icon-comments:before {
    content: "\e645";
}
.fl-icon-phone:before {
    content: "\e646";
}
.fl-icon-copy:before {
    content: "\e647";
}
.fl-icon-paper-clip:before {
    content: "\e648";
}
.fl-icon-fullscreen:before {
    content: "\e649";
}
.fl-icon-reorder:before {
    content: "\e64a";
}
.fl-icon-list-ul:before {
    content: "\e64b";
}
.fl-icon-sort:before {
    content: "\e64c";
}
.fl-icon-file-alt:before {
    content: "\e64d";
}
.fl-icon-envelope-alt:before {
    content: "\e64e";
}
.fl-icon-circle-blank:before {
    content: "\e64f";
}
.fl-icon-circle:before {
    content: "\e650";
}
.fl-icon-shield:before {
    content: "\e651";
}
.fl-icon-chevron-sign-right:before {
    content: "\e652";
}
.fl-icon-chevron-sign-left:before {
    content: "\e654";
}
.fl-icon-chevron-sign-down:before {
    content: "\e655";
}
.fl-icon-file:before {
    content: "\e656";
}
.fl-icon-file-text:before {
    content: "\e657";
}
.fl-icon-thumbs-up:before {
    content: "\e658";
}
.fl-icon-logo-webby:before {
    content: "\e687";
}
.fl-icon-logo-time:before {
    content: "\e688";
}
.fl-icon-logo-nytimes:before {
    content: "\e68a";
}
.fl-icon-logo-brw:before {
    content: "\e68c";
}
.fl-icon-logo-aimia:before {
    content: "\e68d";
}
.fl-icon-trophy:before {
    content: "\e68e";
}
.fl-icon-tick:before {
    content: "\e68f";
}
.fl-icon-next:before {
    content: "\e690";
}
.fl-icon-last:before {
    content: "\e691";
}
.fl-icon-first:before {
    content: "\e692";
}
.fl-icon-prev:before {
    content: "\e693";
}
.fl-icon-flag-outline:before {
    content: "\e694";
}
.fl-icon-comment-outline:before {
    content: "\e695";
}
.fl-icon-clipboard-outline:before {
    content: "\e696";
}
.fl-icon-bird:before {
    content: "\e697";
}
.fl-icon-sealed2:before {
    content: "\e698";
}
.fl-icon-unarchive:before {
    content: "\e699";
}
.fl-icon-archive:before {
    content: "\e69a";
}
.fl-icon-search:before {
    content: "\e69b";
}
.fl-icon-share-new:before {
    content: "\e69c";
}
.fl-icon-bid:before {
    content: "\e69d";
}
.fl-icon-youtube:before {
    content: "\e67b";
}
.fl-icon-reviews2:before {
    content: "\e683";
}
.fl-icon-skrill:before {
    content: "\e684";
}
.fl-icon-paypal-logo:before {
    content: "\e69e";
}
.fl-icon-caret-down:before {
    content: "\e69f";
}
.fl-icon-arrow-curve:before {
    content: "\e6a0";
}
.fl-icon-verified:before {
    content: "\e6a2";
}
.fl-icon-cross-thin:before {
    content: "\e6a3";
}
.fl-icon-exclamation:before {
    content: "\e6ab";
}
.fl-icon-round-square:before {
    content: "\e6ac";
}
.fl-icon-instagram:before {
    content: "\e6ad";
}
.fl-icon-chevron-left:before {
    content: "\e6b8";
}
.fl-icon-arrow-left:before {
    content: "\e6bb";
}
.fl-icon-arrow-up:before {
    content: "\e6ba";
}
.fl-icon-logo-aimia2:before {
    content: "\e6bc";
}
.fl-icon-dollar:before {
    content: "\e6b9";
}
.fl-icon-user:before {
    content: "\e66d";
}
.fl-icon-users:before {
    content: "\e66e";
}
.fl-icon-user-add:before {
    content: "\e66f";
}
.fl-icon-house:before {
    content: "\e670";
}
.fl-icon-camera:before {
    content: "\e671";
}
.fl-icon-cog:before {
    content: "\e672";
}
.fl-icon-tools:before {
    content: "\e673";
}
.fl-icon-newspaper:before {
    content: "\e674";
}
.fl-icon-hourglass:before {
    content: "\e675";
}
.fl-icon-credit-card:before {
    content: "\e676";
}
.fl-icon-bars:before {
    content: "\e677";
}
.fl-icon-logout:before {
    content: "\e678";
}
.fl-icon-login:before {
    content: "\e679";
}
.fl-icon-pictures:before {
    content: "\e67a";
}
.fl-icon-facebook2:before {
    content: "\e67d";
}
.fl-icon-pinterest-alt:before {
    content: "\e680";
}
.fl-icon-linkedin-alt:before {
    content: "\e682";
}
.fl-icon-dribbble-alt:before {
    content: "\e685";
}
.fl-icon-upload:before {
    content: "\e686";
}
.fl-icon-facebook:before {
    content: "\e681";
}
.fl-icon-googleplus:before {
    content: "\e67e";
}
.fl-icon-briefcase:before {
    content: "\e6a1";
}
