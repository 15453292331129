/**
 * Buttons
 *
 * .btn can be applied to `<a>`, `<button>` and  `<label>` elements.
 *
 * Styleguide: Buttons
 */

/**
 * Base styles & states
 *
 * :hover    - Hover state of the button
 * [disabled] - Dims the button when disabled
 * .btn-primary - A button to show the primary action in a component
 * .btn-info - Primary button
 * .btn-success - A button for positive actions
 * .btn-danger - Warning button
 * .btn-plain - Button with a transparent background and border
 * .btn-plain-alt - Button with a transparent background and border for light backgrounds
 * .hireme-btn - For when a employer wants to hire a freelancer
 * .processing - For when a button is processing a request
 *
 * Markup: default-button.html
 *
 * Weight: -10
 *
 * Styleguide: Buttons - Base Styles
 */

/**
 * Button sizes
 *
 * Different available button sizes
 *
 * .btn-mini - A mini sized version of the button
 * .btn-small -  A small version of the button
 * .btn-large -  A large version of the button
 * .btn-xlarge -  A extra large version of the button
 * .btn-xxlarge -  A double xl sized version of the button
 *
 * Markup: default-button.html
 *
 * Weight: -10
 *
 * Styleguide: Buttons - Sizes
 */

/**
 * Button Styles
 *
 * Different styles of buttons for different purposes.
 *
 * Weight: 1
 *
 * Styleguide: Buttons - Styles
 */

/**
 * Hire me button
 *
 * For when a employer wants to hire a freelancer
 *
 * Markup: hireme-button.html
 *
 * Styleguide: Buttons - Styles - Hire Me
 */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/ceaser.custom.less";
@import (reference) "theme/typography.less";
@import (reference) "dropdown.less";

// Button skin constructor.
// Sets the skin/colour for the button.
// If something does not need a variable, do not put it here. This is to prevent issues with the compiled CSS being too large for IE.

.button-skin-constructor(@bg-color, @bg-hover-color, @border-color, @border-hover-color, @text-color, @text-hover-color, @text-shad-color, @text-weight:bold, @shad-color:@bg-color, @shad-hover-color:@bg-color, @shad-size:1px, @shad-position:inset) {
    background: @bg-color;
    border: 1px solid @border-color;
    color: @text-color;
    font-weight: @text-weight;
    text-shadow: 0 -1px transparent;

    &:hover {
        background: @bg-hover-color;
        border-color: @border-hover-color;
        color: @text-hover-color;
    }

    &:active {
        background: darken(@bg-color, 2%);
    }

    &.is-selectedBtn {
        background: darken(@bg-color, 2%);
        border-color: darken(@bg-color, 2%);
    }
}

// Button padding constructor.
// Sets the padding and icon offset for the button.
// If something does not need a variable, do not put it here. This is to prevent issues with the compiled CSS being too large for IE.
// 1. The @button-height variable is to position the text a certain distance from the left edge, to allow room for the Freelancer bird icon.
// 2. [class*="fl-icon-"] is to correctly position any icons that may be included in the button.

.button-constructor(@vertical-padding, @horizontal-padding, @icon-h-margin, @icon-v-margin:0) {
    @button-height: ((@vertical-padding * 2px) + 18px); // 1.
    padding: @vertical-padding @horizontal-padding;

    &:hover {
        text-decoration: none;
    }

    [class*="fl-icon-"],
    [class*="flicon-"] { // 2.
        margin-right: @icon-h-margin;
        margin-top: @icon-v-margin;
    }

    &.fl-bt-skin {
        padding-bottom: (@vertical-padding);
        padding-left: (@button-height + (0.4 * @button-height));
        padding-top: (@vertical-padding);
    }
}

// Default button style.
// Anything here does not need variables and is built upon the default, medium-sized button.
// Avoid changing the button constructor, font size, border-radius and fl-icon positioning.
// Many of the rules here are specified for the other button sizes.
// 1. Button constructor for padding and icon margin of button.
// 2. Font size for this particular button size.
// 3. Border radius for this particlar button size.
// 4. Font size for the icon on this particular button size.
// 5. Margin offset for icon when on right of button text, and margin offset on .btn-mini.
// 6. Calculated width of Hire Me bird icon area on this particular button size.
// 7. Cache the loading gif image so that it is ready to display as soon as the button is pressed.
// 8. Changed the colour of the :focus outline because the original blue was not to Diego's liking.
// 9. Disabled buttons have the same style as default, but lower opacity.
// 10. This applies a border onto plain buttons and removes the shadow.

.btn {
    .quaternary-light-bt-skin;
    .button-constructor(8px, 18px, 7px, -3px); // 1.
    .font-size(@font-size-ui); // 2.
    background-clip: border-box; // required by IE11
    border-radius: @border-radius;
    cursor: pointer;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    line-height: 18px;
    text-align: center;

    &:hover {
        text-decoration: none;
    }

    [class*="fl-icon-"],
    [class*="flicon-"] {
        display: inline-block;
        font-size: 16px; // 4.
        height: 12px;
        line-height: 12px;
        vertical-align: middle;

        &.fl-icon-alt { // 5.
            margin-left: 0;
            margin-right: 0;
        }

        &.btn-mini { // 5.
            margin-top: -2px;
        }
    }

    [class*="flicon-"] {
        display: inline-block;
        height: 18px;
        vertical-align: middle;
        width: 18px;
    }

    &.fl-bt-skin [class*="fl-icon-"],
    &.fl-bt-skin::after { // 6.
        width: 38px;
    }

    &::after { // 7.
        background-image: url("/static/css/images/ajax-loader-med-dark.gif");
        content: "";
        z-index: -1;
    }

    &:focus { // 8.
        outline-color: @septenary-color-light;
    }

    input[type="radio"] {
        margin-left: -7px;
        margin-right: 7px;
    }

    &[disabled],
    &.disabled {
        .disabled-bt-skin;
        cursor: not-allowed;
        opacity: 0.5; // 9.
        transition: none;

        &:hover,
        &:active {
            box-shadow: none;
        }

        &.processing {
            color: @septenary-color-xxlight;
        }
    }

    &.btn-plain,
    &.btn-plain-alt { // 10.
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-shadow: none;

        &:hover {
            box-shadow: none;
            text-shadow: none;
        }
    }
}

// Bootstrap button size overrides.
// These are the same classes used in bootstrap for changing button size.
// The rules here are explained above in the comments for the default .btn.
// 1. Note that .btn-xlarge is the same as .btn-xxlarge, however has a smaller height of 56px.

.btn-mini {
    .button-constructor(4px, 12px, 3px, -1px);
    .font-size(@font-size-detail);
    border-radius: @border-radius;
    font-weight: normal;

    [class*="fl-icon-"] {
        .font-size(@font-size-detail);
    }
}

.btn-small {
    .button-constructor(6px, 13px, 5px, -2px);
    .font-size(@font-size-ui);
    border-radius: @border-radius;

    [class*="fl-icon-"] {
        .font-size(@font-size-ui);
    }
}

.btn-large {
    .button-constructor(13px, 22px, 9px, -4px);
    .font-size(@font-size-heading3);
    border-radius: @border-radius;
    font-weight: bold;

    [class*="fl-icon-"] {
        .font-size(@font-size-heading3);
    }

    &.btn-plain,
    &.btn-plain-alt,
    &.btn-plain[disabled]:active,
    &.btn-plain-alt[disabled]:active {
        border-width: 2px;
        padding: 12px 21px;
    }
}

.btn-xlarge {
    .font-size(@font-size-heading3);
    .button-constructor(18px, 26px, 9px, -5px);
    border-radius: @border-radius;
    font-weight: bold;

    [class*="fl-icon-"] {
        font-size: 21px;
    }

    &.btn-plain,
    &.btn-plain-alt,
    &.btn-plain[disabled]:active,
    &.btn-plain-alt[disabled]:active {
        border-width: 2px;
        padding: 17px 25px;
    }
}

.btn-xxlarge {
    .button-constructor(22px, 26px, 9px, -5px);
    .font-size(@font-size-heading2);
    border-radius: @border-radius;
    font-weight: bold;

    [class*="fl-icon-"] {
        .font-size(@font-size-heading2);
    }

    &.btn-plain,
    &.btn-plain-alt,
    &.btn-plain[disabled]:active,
    &.btn-plain-alt[disabled]:active {
        border-width: 2px;
        padding: 21px 25px;
    }
}

.btn-link {
    background: none;
    border: none;
    box-shadow: none;
    color: @link-color;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    padding: 4px 12px;

    &:hover {
        background: none;
        border: none;
        color: @link-color;
        text-decoration: underline;
    }

    &:active {
        box-shadow: none;
    }

    &.processing {
        background: none;
        border: none;
        color: transparent;

        &:hover {
            background: none;
            border: none;
            color: transparent;
        }
    }
}

.Button--basic {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;

    &:hover {
        background: none;
        border: none;
    }

    &:active {
        box-shadow: none;
    }
}

.small-branded-button {
    .button-constructor(6px, 13px, 5px);
    padding-left: 43px;
    position: relative;

    &::before {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        top: 0;
        width: 31px;
        z-index: 1;
    }

    [class*="fl-icon-"] {
        left: 13px;
        position: absolute;
        top: 8px;
        z-index: 2;
    }
}

.small-button-alt {
    .button-constructor(28px, 13px, 24px);
    border-radius: 17px;
    .font-size(@font-size-landing);

    [class*="fl-icon-"] {
        font-size: 20px;
    }
}

// Skin specifications for different classes.
// These should not be changed as they set the colour of buttons based on class.

.btn-primary {
    .primary-bt-skin();
}

.btn-info {
    .secondary-bt-skin();
}

.btn-success {
    .quinary-bt-skin();
}

.btn-danger {
    .tertiary-bt-skin();

    &:hover {
        background: @senary-color-light;
        border-color: @senary-color;
    }
}

/**
 * Facebook button
 *
 * For any button whose action relates to facebook
 *
 * Markup: facebook-button.html
 *
 * Styleguide: Buttons - Styles - Facebook
 */

.btn-facebook {
    .facebook-bt-skin();

    &--hasIcon {
        align-items: center;
        display: inline-flex;
    }

    &-icon {
        line-height: 1;
        margin-right: @spacing-xxsmall;
    }
}

/**
 * LinkedIn button
 *
 * For any button whose action relates to LinkedIn
 *
 * Styleguide: Buttons - Styles - Facebook
 */
.btn-linkedin {
    .linkedin-bt-skin();
}

.btn-plain {
    .minimal-bt-skin();

    &.is-active {
        .minimal-bt-skin-active();
        cursor: default;
    }
}

.btn-plain-alt {
    .minimal-bt-skin-alt();

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}

.btn-caution {
    .senary-bt-skin();
}

// Skins.
// Sets the exact colours of buttons from the variables file.
// Be careful when changing values here as they will change the button colours. Only use variables here; do not use hex values.

// .btn-primary (orange)
.primary-bt-skin() {
    .button-skin-constructor(
        @flamingo-pink, // background color
        @flamingo-pink-light, // background hover color
        @flamingo-pink, // border color
        @flamingo-pink-light,  // border color hover
        @text-color-light, // text color
        @text-color-light,  // text color hover
        bold, // text weight
        fade(@septenary-color-xxxlight, 20%), // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

// .btn-info (blue)
.secondary-bt-skin() {
    .button-skin-constructor(
        @primary-color-dark,  // background color
        @primary-color, // background hover color
        @primary-color-dark,  // border color
        @primary-color,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @primary-color-dark,  // text shadow color
        bold,  // text weight
        fade(@septenary-color-xxxlight, 20%),  // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

// .btn-danger (red)
.tertiary-bt-skin() {
    .button-skin-constructor(
        transparent,  // background color
        @senary-color-light, // background hover color
        @quaternary-color-xxlight,  // border color
        @senary-color-dark,   // border color hover
        @text-color-mid,  // text color
        @text-color-light,   // text color hover
        @senary-color-dark,  // text shadow color
        bold,  // text weight
        fade(@senary-color-dark, 20%),  // shadow color
        fade(@senary-color-dark, 20%) // shadow hover color
    );
    background: none;
}

.quaternary-bt-skin() {
    .button-skin-constructor(
        @quaternary-color,  // background color
        lighten(@quaternary-color,3%), // background hover color
        @quaternary-color,  // border color
        lighten(@quaternary-color,3%),   // border color hover
        @text-color-mid,  // text color
        @text-color-mid,   // text color hover
        transparent,  // text shadow color
        bold,  // text weight
        transparent,  // shadow color
        transparent // shadow hover color
    );
}

.quaternary-light-bt-skin() {
    .button-skin-constructor(
        @quaternary-color-xxxxxlight, // background color
        @quaternary-color-xxxxlight, // background hover color
        @quaternary-color-xxlight,  // border color
        @quaternary-color-xxlight,   // border color hover
        @quaternary-color-light,  // text color
        @quaternary-color-light,   // text color hover
        transparent,  // text shadow color
        bold,  // text weight
        fade(@septenary-color-xxxlight, 20%),  // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

// .btn-success (green)
.quinary-bt-skin() {
    .button-skin-constructor(
        @quinary-color-dark,  // background color
        @quinary-color, // background hover color
        @quinary-color-dark,  // border color
        @quinary-color,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @quinary-color-dark,  // text shadow color
        bold,  // text weight
        fade(@septenary-color-xxxlight, 20%),  // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

// .btn-caution (red)
.senary-bt-skin() {
    .button-skin-constructor(
        @secondary-color,  // background color
        @secondary-color-light, // background hover color
        @secondary-color,  // border color
        @secondary-color-light,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @secondary-color-dark,  // text shadow color
        bold,  // text weight
        fade(@septenary-color-xxxlight, 20%),  // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

// .btn-plain (transparent)
.minimal-bt-skin() {
    .button-skin-constructor(
        transparent,  // background color
        transparent, // background hover color
        @quaternary-color-xlight,  // border color
        @quaternary-color-light,   // border color hover
        @quaternary-color-light,  // text color
        @text-color-dark,   // text color hover
        transparent,  // text shadow color
        bold,  // text weight
        transparent,  // shadow color
        transparent // shadow hover color
    );
}

.minimal-bt-skin-active() {
    .button-skin-constructor(
        @septenary-color-xxxdark,  // background color
        @septenary-color-xxxdark, // background hover color
        @septenary-color-xxxdark,  // border color
        @septenary-color-xxxdark,   // border color hover
        @septenary-color-xxxlight,  // text color
        @septenary-color-xxxlight,   // text color hover
        transparent,  // text shadow color
        bold,  // text weight
        transparent,  // shadow color
        transparent // shadow hover color
    );
}

// .btn-plain-alt (transparent, with white border/text)
.minimal-bt-skin-alt() {
    .button-skin-constructor(
        transparent,  // background color
        rgba(255,255,255,0.2), // background hover color
        @septenary-color-xxxlight,  // border color
        @septenary-color-xxxlight,   // border color hover
        @septenary-color-xxxlight,  // text color
        @septenary-color-xxxlight,   // text color hover
        transparent,  // text shadow color
        bold,  // text weight
        transparent,  // shadow color
        transparent // shadow hover color
    );
    background: none;
}

// .btn (default)
.septenary-bt-skin() {
    .button-skin-constructor(
        @quaternary-color-xxxxxlight, // background color
        @quaternary-color-xxxxlight, // background hover color
        @quaternary-color-xxlight, // border color
        @quaternary-color-xxlight,  // border color hover
        @septenary-color-dark, // text color
        @septenary-color-xxdark,  // text color hover
        transparent, // text shadow color
        bold, // text weight
        @septenary-color-xxxlight, // shadow color
        @septenary-color-xxlight // shadow hover color
    );
}

// [disabled] button skin (low opacity)
.disabled-bt-skin() {
    .button-skin-constructor(
        @quaternary-color-xxxxlight,  // background color
        @quaternary-color-xxxxlight, // background hover color
        @quaternary-color-xxlight,  // border color
        @quaternary-color-xxlight,   // border color hover
        @quaternary-color-xlight,  // text color
        @quaternary-color-xlight, // text color hover
        transparent // text shadow color
    );
}

.facebook-bt-skin() {
    .button-skin-constructor(
        darken(@facebook-color-button, 5%),  // background color
        @facebook-color-button,  // background hover color
        darken(@facebook-color-button, 5%),  // border color
        darken(@facebook-color-button, 5%),   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        transparent  // text shadow color
    );

    [class*="flicon"] {
        fill: @text-color-light;
        stroke: transparent;
    }
}

.linkedin-bt-skin() {
    .button-skin-constructor(
        @linkedin-color,  // background color
        darken(@linkedin-color, 10%), // background hover color
        transparent,  // border color
        transparent,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        darken(@linkedin-color, 20%)  // text shadow color
    );
}

/**
 * Toggle button
 *
 * A toggle switch. Wraps around .switch-light
 *
 * Markup: checkbox-switch.html
 *
 * Styleguide: Forms - Form Elements - Toggle Button
 */

.btn-toggle {
    .button-skin-constructor(
        @quaternary-color,  // background color
        @quaternary-color-light, // background hover color
        transparent,  // border color
        transparent,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @quaternary-color-dark  // text shadow color
    );
}

.no-skin() {
    .button-skin-constructor(
        transparent,  // background color
        transparent, // background hover color
        transparent,  // border color
        ransparent,   // border color hover
        @text-color-dark,   // text color
        @text-color-dark, // text color hover
        transparent  // text shadow color
    );
}

.hireme-btn-skin() {
    .button-skin-constructor(
        @primary-color-dark, // background hover color
        @primary-color,  // background color
        @primary-color-dark,  // border color
        @primary-color,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @primary-color-dark  // text shadow color
    );
}

.hireme-rate-skin() {
    .button-skin-constructor(
        @septenary-color-xxxlight,  // background color
        @septenary-color-xxlight, // background hover color
        @septenary-color-xxxlight,  // border color
        @septenary-color-xxlight,   // border color hover
        @septenary-color-xxxdark,  // text color
        @septenary-color-xxxdark,   // text color hover
        transparent  // text shadow color
    );
}

.hireme-rate-dark-skin() {
    .button-skin-constructor(
        @quaternary-color-dark,  // background color
        @quaternary-color-xdark, // background hover color
        @quaternary-color-dark,  // border color
        @quaternary-color-xdark,   // border color hover
        @septenary-color-xxxlight,  // text color
        @septenary-color-xxxlight,   // text color hover
        transparent  // text shadow color
    );
}

// Edit buttons

/* edit bt */

.btn-edit {
    .button-skin-constructor(
        @tertiary-color, // background color
        @tertiary-color-light, // background hover color
        @tertiary-color, // border color
        @tertiary-color-light,  // border color hover
        @text-color-light, // text color
        @text-color-light,  // text color hover
        @tertiary-color-xxdark, // text shadow color
        bold, // text weight
        fade(@septenary-color-xxxlight, 20%), // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

.btn-edit {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 62%;
    height: 30px;
    padding: 0;
    width: 30px;

    &.btn-small {
        height: 20px;
        width: 20px;
    }
}

// Toggle button - wraps around .switch-light

.btn-toggle {
    line-height: 0;
}

.btn-toggle-name {
    display: inline-block;
    margin: 10px 0 0 2px;
    vertical-align: top;
}

// Hireme button

.hireme-btn {
    .hireme-btn-skin();
}

// This removes default styling for buttons.
.button-reset {
    background: none;
    border: none;
    padding: 0;
}

.processing {
    .disabled-bt-skin();
    color: @septenary-color-xxlight;
    position: relative;

    &:hover {
        color: @septenary-color-xxlight;
        cursor: default;
    }

    &::after {
        background-image: url("/static/css/images/ajax-loader-med-dark.gif");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.75;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
    }
}

/**
 * Button group
 *
 * Used to show a group of related actions
 *
 * .btn-small - A small version
 * .btn-large - A large version
 *
 * Markup: btn-group.html
 *
 * Styleguide: Buttons - Button Group
 */

/**
 * Radio group
 *
 * Used to show a group of related actions where only one can be chosen.
 *
 * Markup: radio-group.html
 *
 * Styleguide: Buttons - Radio Group
 */

/* 1. used the combat using display:block on labels as a shim for ie9 < inability to display placeholders */

.button-group {
    display: block;
    position: relative;

    > .btn {
        margin-bottom: 0;
        position: relative;

        &:not(:first-child):not(:last-of-type) {
            .background-clip();
            border-radius: 0;
        }

        &:first-child {
            margin-right: 0;

            &:not(:last-of-type) {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:last-of-type {
            margin-left: 0;

            &:not(:first-child) {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.dropdown-toggle {
            border-radius: 0 @border-radius @border-radius 0;
            border-right-style: solid;
            margin-left: 0;
        }

        /* Retain left border, and hide below sibling instead */
        + .btn {
            margin-left: -1px;
        }
    }

    input {
        z-index: 1;

        &:checked::before {
            background: transparent;
            bottom: 0;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
}

/**
 * Dropdown button
 *
 * Button with primary clickable action, and secondary action in dropdown
 *
 * .btn-small - A small version
 * .btn-large - A large version
 *
 * Markup: button-dropdown.html
 *
 * Styleguide: Buttons - Dropdown Button
 */

.btn-dropdown {
    .button-group();
    display: inline-flex;
    position: relative;

    &.is-open {
        .btn-dropdown-trigger::before {
            -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
            transform: rotate(180deg);
        }

        .btn-dropdown-options {
            display: block;
        }
    }

    .btn {
        position: static;

        /* Corrects IE Flexbug */
        &:first-child {
            flex: 0 1 auto;
        }
    }
}

.btn-dropdown-trigger {
    &::before {
        .easeInQuad(.1s);
        border-color: @quaternary-color-light transparent transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: "";
        display: inline-block;
        height: 0;
        vertical-align: middle;
        width: 0;
    }

    &.btn-small::before {
        border-width: 4px 4px 0;
    }

    &.btn-large::before {
        border-width: 6px 6px 0;
    }

    &.btn-info {
        &::before {
            border-color: @septenary-color-xxxlight transparent transparent transparent;
        }
    }
}

.btn-dropdown-options {
    .dropdown-nav();
    left: 0;
    top: 100%;
    width: auto;

    a {
        .dropdown-nav-item();
        white-space: nowrap;
    }

    &--reverse {
        left: auto;
        right: 0;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.Button-icon {
    line-height: 0;
    margin-right: 0.3em;
}

/**
 * Recruiter buttons
**/

.recruiter-bt-skin() {
    .button-skin-constructor(
        @recruiter-color,  // background color
        @recruiter-color, // background hover color
        transparent,  // border color
        transparent,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        darken(@recruiter-color, 20%)  // text shadow color
    );
}

.btn-recruiter {
    .recruiter-bt-skin();
}

.btn-plain-recruiter {
    .minimal-bt-skin();
    border-color: @recruiter-color;
    color: @recruiter-color;

    &:hover {
        border-color: darken(@recruiter-color, 10%);
        color: darken(@recruiter-color, 10%);
    }
}
