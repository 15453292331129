/* this is the basic styling and fallback for svg icons not the icomoon font icon */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

// SVG icons reset
// 2. default color of flicon
// 3. default stroke width of flicon
// 4. this will allow the stroke width of lineicons to not be scaled when the icon's size changed
// 5. .has-compact-flicon classes need to be applied to a parent element of the flicon import
//   Note: .has-compact-flicon svg's currently only prepared for: flicon-setting, flicon-chat
// 6. .is-animated classes need to be applied to a parent element of the flicon import
// 7. height can not be auto due to IE
// 8. Filled elements should have no stroke

@stroke-width: 1.2px;
.keyframes(spinner, {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
});

[class*="flicon-"] {
    display: inline-block;
    width: 24px;
    height: 24px; // 7
    fill: transparent;
    stroke: @quaternary-color-light; // 2
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: @stroke-width; // 3

    polygon, path, polyline, rect, circle, line, ellipse { // 4
        vector-effect: non-scaling-stroke;
    }

    // 5
    .has-compact-flicon & {
        .flicon-default {
            display: none;
        }
        .flicon-compact {
            display: block;
            fill: @quaternary-color-light;
            stroke-width: 0;
        }
    }

    .has-fill & {
        fill: @quaternary-color-xxxxlight;        // 2
        stroke-width: 0; /* 8. */

        [fill="none"] { /* 8. */
            stroke-width: @stroke-width;
        }
    }

    .flicon-compact {
        display: none;
    }
}

.flicon-uploading {
    // 6.
    .is-animated & {
        .has-animation {
            position: absolute;
            .animation(
                @name: spinner,
                @duration: 1.5s,
                @delay: 0s,
                @ease: linear,
                @iteration-count: infinite
            );
            -moz-animation: none !important; // transform-origin bug in Firefox
            .transform-origin(50% 50%);
        }
    }
}
