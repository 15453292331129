/*************************************************/
/* tabbed section */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.tabbed-section {
    position: relative;
}

.nav-tab {
    position: relative;
    display: inline-block;
    border-radius: 17px;
    > a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px 24px;
        color: @text-color-light;
        &:hover {
            text-decoration: none;
        }
    }

    &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: '';
        width: 0px;
        height: 0px;
        margin-left: -9px;
        border-style: solid;
        border-width: 9px 9px 0 9px;
        border-color: transparent;
    }
    &.active {
        background-color: @primary-color;
        .transition(~".2s ease-in-out");
        &:after {
            bottom: -9px;
            .transition(.2s, ease-in-out);
            .transition-delay(.1s);
            border-color: @primary-color transparent transparent transparent;
        }
    }
}
