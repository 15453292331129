/* Main Layout file for Freelancer.com's header, the same CSS is used for logged-out version and landing version */
@import (reference) "theme/variables.less";
@import (reference) "libs/ceaser.custom.less";
@import (reference) "libs/mixins.less";
@import (reference) "flux/modules/dropdown.less";
@import (reference) "flux/modules/inputs.less";
@import "flux/modules/FlDropDown.less";
@import "flux/layout/LoginSection.less";

// layout specific variable
@header-height: 64px;
@header-logo-network-width: 160px;
@fl-logo-width: 145px;
@header-padding: 16px;
@secondary-nav-color: @quaternary-color-xxdark;
@primary-link-padding: (@header-height - 18px)/2;
@secondary-nav-height: 48px;

.fl-header {
    background: @septenary-color-xxxlight;
    background-color: @septenary-color-xxxlight;
    color: @text-color-dark;
    display: block;
    line-height: initial;
    min-height: @header-height;

    &--arrow {
        background: @septenary-color-xxxdark;
        color: @septenary-color-xxxlight;

        .primary-navigation-section {
            background: @septenary-color-xxxdark;
        }
    }

    &--transparent {
        background: transparent;
        position: absolute;
        width: 100%;
        /* Needs to have the this z-index
           or chat will be above sidebar */
        z-index: @z-index-sidebar;

        .primary-navigation-section,
        .primary-navigation-section.is-scrolled {
            background: transparent;
            box-shadow: none;
            position: static;
        }

        .header-notification-finance-link,
        .primary-navigation-link,
        .LoginSection-btn {
            color: @text-color-light;
        }

        .LoginSection-btn:hover {
            opacity: 0.8;
        }

        .flicon-logo-fullcolor path {
            fill: @septenary-color-xxxlight;
            stroke: none;
        }

        .primary-navigation-list > li:hover .primary-navigation-link,
        .primary-navigation-list .fl-primary-nav-item > li:hover .primary-navigation-link,
        .header-notification-finance:hover .header-notification-finance-link {
            color: @text-color-light;
            opacity: 0.8;
        }

        .sites-network.is-active .Icon,
        .sites-network:hover .Icon,
        .header-notifications > li:hover .open_tab .Icon {
            fill: @text-color-light;
            opacity: 0.8;
        }

        .nav-search-input {
            background: fade(@septenary-color-xxxlight, 30%);
            border-color: transparent;
            color: @text-color-light;

            &:focus {
                border-color: @quaternary-color-xxxlight;
            }

            &::-webkit-input-placeholder {
                color: @text-color-light;
            }

            &::-moz-placeholder {
                color: @text-color-light;
            }

            &:-ms-input-placeholder {
                // !important to override forms.less
                color: @text-color-light !important;
            }
        }

        .nav-search-link,
        .nav-search-trigger {
            .Icon {
                fill: @text-color-light;
            }

            &:hover .Icon {
                fill: @text-color-light;
                opacity: 0.8;
            }
        }
    }

    &--transparentLight {
        .flicon-logo-fullcolor {
            path {
                fill: @septenary-color-xxxlight;
                stroke: none;
            }

            g {
                path {
                    fill: @primary-color-light;
                }
            }

            .fl-logo-tail {
                fill: @primary-color-light;
            }
        }
    }

    &-dropdown {
        display: none;
        left: -8px;
        position: absolute;
        top: 64px;
    }

    .LoginSection {
        margin-left: auto;
    }

    .fl-logo-container {
        display: table-cell;
        vertical-align: middle;
        width: auto;
    }

    .fl-logo {
        display: inline-block;
        line-height: @header-height;
        transition: all 0.2s ease-in;
        width: auto;

        .flicon-logo-fullcolor {
            height: 36px;
            stroke-width: 0;
            vertical-align: middle;
            width: @fl-logo-width;

            &--arrow {
                width: 267px;
            }

            &--freightlancer {
                width: 163px;
            }
        }
    }
}

.navigation-section-inner {
    .site-section-inner();
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.primary-navigation {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;

    &-section {
        .box-shadow-level-1();
        background: @septenary-color-xxxlight;
        min-height: @header-height;
        position: relative;
        z-index: 1020;

        &--sticky {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }

    &-list {
        .list-unstyled;
        display: inline-block;

        &:hover {
            .primary-navigation-link::after {
                bottom: -11px;
            }
        }

        > li {
            display: inline-block;
            padding: 0 16px;
            position: relative;

            &:hover {
                .primary-subnav {
                    display: block;
                }

                .fl-header-dropdown {
                    display: block;
                }

                .primary-navigation-link {
                    color: @primary-color-dark;
                    text-decoration: none;
                }
            }
        }
    }

    &-link {
        .dropdown-indicator(-12px);
        .type(landing);
        color: @text-color-dark;
        display: inline-block;
        letter-spacing: -0.024em;
        padding: 20px 0px;
        position: relative;

        &--noSubNav {
            &::before {
                display: none;
            }
        }

        &--showOnLarge {
            display: none;

            @media (min-width: @breakpoint-desktop-large) {
                display: inline-block;
            }
        }

    }

    .is-mobile-first & {
        @media (max-width: @breakpoint-desktop-small) {
            display: none;
        }
    }
}

/* secondary navigation */
.secondary-navigation {
    &-section {
        background: @secondary-nav-color;
        color: @septenary-color-xxxlight;

        &--transparent {
            background: fade(@secondary-nav-color, 65%);
            margin-bottom: -50px;
            position: relative;
            z-index: 1;
        }

        &-inner {
            display: table;
            position: relative;
        }
    }

    &-list {
        @secondary-navigation-item-padding: 16px;

        .list-unstyled;
        display: inline-block;
        margin-left: -(@secondary-navigation-item-padding);

        .secondary-navigation-item,
        > li {
            display: inline-block;
            padding: 0 @secondary-navigation-item-padding;
            position: relative;

            &:hover {
                background: fade(@septenary-color-xxxlight, 15%);
            }
        }
    }

    &-title {
        .type(ui);
        display: inline-block;
        margin-right: 50px;
        text-transform: uppercase;

        a {
            color: @septenary-color-xxxlight;
            text-decoration: none;
        }
    }

    &-link {
        .type(ui);
        color: @text-color-light;
        display: inline-block;
        line-height: 1.2;
        padding: 17px 0;
        position: relative;
        transition: none;

        &::after {
            .transition(all, 0.2s, ease-in);
            background: @quaternary-color-xxxlight;
            bottom: 0;
            content: "";
            display: none;
            height: 4px;
            left: -16px;
            opacity: 0;
            position: absolute;
            right: -16px;
        }

        &:hover {
            color: @text-color-light;
            text-decoration: none;
        }

        &.is-active {
            color: @text-color-light;

            &::after {
                background: @primary-color-xlight;
                display: block;
                opacity: 1;
            }

        }
    }

    .is-mobile-first & {
        &-section {
            @media (max-width: @breakpoint-desktop-small) {
                display: none;
            }
        }
    }
}

.skill-navigation {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;

    &-section {
        .box-shadow-level-1();
        background: @secondary-nav-color;
        position: relative;
        z-index: 1020;

        &--transparent {
            background: fade(@secondary-nav-color, 65%);
            margin-bottom: -50px;
            position: relative;
            z-index: 1;
        }
    }

    &-list {
        .list-unstyled;
        display: inline-block;

        &:hover {
            .skill-navigation-link::after {
                bottom: -11px;
            }
        }
    }

    &-item {
        display: inline-block;
        padding: 0 @spacing-small;
        position: relative;

        &:hover {
            .primary-subnav {
                display: block;
            }

            .fl-header-dropdown {
                display: block;
                top: 54px;
            }

            .skill-navigation-link {
                color: @primary-color-light;
                text-decoration: none;
            }
        }
    }

    &-title {
        .type(ui);
        color: @septenary-color-xxxlight;
        display: inline-block;
        margin-right: @spacing-xlarge;
        text-transform: uppercase;
    }

    &-link {
        .dropdown-indicator(-12px);
        .type(ui);
        color: @text-color-light;
        display: inline-block;
        letter-spacing: -0.024em;
        padding: (@spacing-mid - @spacing-xxsmall) 0px;
        position: relative;

        &--noSubNav {
            &::before {
                display: none;
            }
        }
    }

    .is-mobile-first & {
        &-section {
            @media (max-width: @breakpoint-desktop-small) {
                display: none;
            }
        }
    }
}

.header-post-project {
    .vertical-align(absolute);
    right: 0;
}

@media all and (min-width: (@breakpoint-desktop-small + (@header-logo-network-width * 2))) {
    html:not(.is-responsive).logged-in & {
        .fl-logo-container {
            left: 24px;
            position: absolute;
        }

        .header-user-trigger {
            .vertical-align(absolute);
            right: 10px;
        }

        .header-logged-out-section {
            display: block;
            position: absolute;
            top: @header-padding;
        }
    }
}
/* .has-sidebar : pushes nav to the left to make room for the sidebar on the right */

@media all and (min-width: (@breakpoint-desktop-small + (@header-logo-network-width * 2))) {
    html:not(.is-responsive).has-sidebar.logged-in & {
        .header-user-trigger {
            position: static;
            right: auto;
            top: auto;
            transform: none;
        }
    }
}

@media all and (min-width: (@breakpoint-desktop-small + (@header-logo-network-width * 2)+ @contactbar-width)) {
    html:not(.is-responsive).logged-in.has-sidebar& {
        .header-user-trigger {
            .vertical-align(absolute);
            right: (@contactbar-width + 10px);
        }
    }
}

/* header breakpoint on responsive page */
@media all and (min-width: (@breakpoint-desktop-large + (@header-logo-network-width * 2))) {
    html.is-responsive.logged-in & {
        .fl-logo-container {
            left: 24px;
            position: absolute;
        }

        .header-user-trigger {
            .vertical-align(absolute);
            right: 10px;
        }

        .header-logged-out-section {
            display: block;
            position: absolute;
            top: @header-padding;
        }
    }
}

@media all and (min-width: (@breakpoint-desktop-large + (@header-logo-network-width * 2))) {
    html.is-responsive.has-sidebar.logged-in & {
        .header-user-trigger {
            position: static;
            right: auto;
            top: auto;
            transform: none;
        }
    }
}

@media all and (min-width: (@breakpoint-desktop-large + (@header-logo-network-width * 2)+ @contactbar-width)) {
    html.is-responsive.has-sidebar.logged-in & {
        .header-user-trigger {
            .vertical-align(absolute);
            right: (@contactbar-width + 10px);
        }
    }
}

.is-simple-header & {
    .primary-navigation,
    .secondary-navigation-section,
    .LoginSection {
        display: none !important;
    }
}
