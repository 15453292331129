/**
 * source: hint-sizes.less
 *
 * Defines width restricted tooltips that can span
 * across multiple lines.
 *
 * Classes added:
 *  1) hint--small
 *  2) hint--medium
 *  3) hint--large
 *
 */

@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

.@{hintPrefix}small,
.@{hintPrefix}medium,
.@{hintPrefix}large {
    &::after {
      line-height: 1.4em;
      white-space: normal;
    }
}

.@{hintPrefix}small {
    &::before {
        margin-bottom: -10px;
    }

    &::after {
      padding: 4px 8px;
      text-align: center;
      width: @hintSizeSmall;
    }
}

.@{hintPrefix}medium {
    &::after {
      width: @hintSizeMedium;
    }
}

.@{hintPrefix}large {
    &::after {
      width: @hintSizeLarge;
    }
}
