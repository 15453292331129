/**
 * source: hint-core.less
 *
 * Defines the basic styling for the tooltip.
 * Each tooltip is made of 2 parts:
 *  1) body (:after)
 *  2) arrow (:before)
 *
 * Classes added:
 *  1) hint
 */

@import (reference) "theme/variables.less";
@import (reference) "hint-variables.less";
@import (reference) "hint-mixins.less";

[@{hintDataAttribute}] {
  position: relative;
  display: inline-block;
  &:before,
  &:after {
    position: absolute;

    // HACK: Trigger hardware accelerated rendering, otherwise transform was not
    // working on a hidden element
    .vendor(transform, translate3d(0, 0, 0));

    // HACK: visibility is set to hidden because IE & Opera don't support
    // pointer-events on HTML content yet because of which hovering a hidden tooltip
    // shows the tooltip.
    visibility: hidden;
    opacity: 0;
    z-index: @hintZIndex;
    // shouldn't receive pointer events, otherwise even hovering tooltip will make it appear
    pointer-events: none;

    .vendor(transition, 0.3s ease);
    .vendor(transition-delay, @hintHideDelay);
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  &:hover:before,
  &:hover:after {
    // @hintShowDelay will apply as soon as element is hovered.
    .vendor(transition-delay, @hintShowDelay);
  }

  /**
   * tooltip arrow
   */
  &:before {
    content: "";
    position: absolute;
    background: transparent;
    border: @hintArrowBorderWidth solid transparent;
    // move z-index 1 up than :after so that it shows over box-shadow
    z-index: @hintZIndex + 1;
  }

  /**
   * tooltip body
   */
  &:after {
    content: attr(~"@{hintDataAttribute}"); // The magic!
    background: @hintDefaultColor;
    color: @hintDefaultTextColor;
    border-radius: @border-radius;
    padding: @hintVerticalPadding @hintHorizontalPadding;
    font-size: @hintFontSize;
    font-family: @hintFontFamily;
    font-weight: normal;
    line-height: @hintFontSize; // Vertical centering.
    white-space: nowrap; // Prevent breaking to new line.
  }
}

[@{hintDataAttribute}=''] {
  &:before,
  &:after {
    display: none !important;
  }
}
