/* Global states for HTML elements across the site */

/* Interaction patterns */
/* 1. use in conjunction with .is-expanded to create an accordian effect */
/* 2. use in conjunction with .is-collapsed to create an accordian effect */
/* 3. use to hide an element and remove it from the document flow */
/* 4. use to un-hide an element set to display none by default */

@import (reference) "libs/mixins.less";

.is-collapsed { /* 1. */
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
}

.is-expanded { /* 2. */
    max-height: none !important;
}

.is-hidden {
    display: none !important;
}

.is-visible { /* 4. */
    display: inline-block !important;
}

.is-invisible {
    visibility: hidden;
}

.is-transparent {
    opacity: 0.5;
}

[data-state="sticky"] {
    position: fixed;
}

[data-state="hidden"],
.hide {
    display: none;
}

[data-state="visible"],
.show {
    display: block;
}

.is-accessibly-hidden {
    .visibly-hidden();
}
