@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/*  Freelancer branded bootstrap button
    Default is flat, add .fl-bt-3d-skin to make it 3D.
    Sizings are controlled by regular bootstrap classes
*/

.btn.fl-bt-skin {
    position: relative;
    box-shadow: none;
    border: 1px solid @primary-color;
    background: @primary-color;
    background-image: none;
    color: @septenary-color-xxxlight;
    transition: none;

    &:hover {
        background-color: @primary-color-dark;
        border: 1px solid @primary-color-dark;
        color: @septenary-color-xxxlight;

        [class*="fl-icon-"] {
            background-color: @septenary-color-xxxlight;
            transition: background-color .1s ease-out;

            &::after {
                border-color: transparent transparent transparent @septenary-color-xxxlight;
                right: -6px;
                transition: border-color .1s ease-out;
            }
        }
    }

    /* Pressed state for all buttons.
        1. Applies a shadow.
    */

    &:active,
    &:active [class*="fl-icon-"] {
        box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.15), 2px 1px 2px rgba(0, 0, 0, 0.05);
        outline: 0;
    }

    /* Disabled state for buttons.
        1. Ensures the button is the same colour when hovered and in a disabled state.
    */

    &.disabled,
    &[disabled] {
        border: 1px solid @primary-color;
        background-color: @primary-color;
        [class*="fl-icon-"] {
            background-color: @hireme-bg-color;
        }
    }

    /* Freelancer bird icon.
        1. Applies a gradient to the background.
        2. Inserts a small arrow next to the icon using :after.
    */

    [class*="fl-icon-"] {
        display: inline;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        margin: 0;
        width: 36px;
        height: auto;
        background-color: @hireme-bg-color;
        border-radius: 2px 0 0 2px;
        color: @primary-color;
        text-align: center;
        text-shadow: 0 0 transparent;
        line-height: 40px;
        .type(heading2);
        transition: background-color .1s ease-out;

        &::after {
            position: absolute;
            right: -6px;
            top: 50%;
            content: '';
            width: 0px;
            height: 0px;
            margin-top: -7px;
            border-style: solid;
            border-width: 6px 0 6px 6px;
            border-color: transparent transparent transparent @hireme-bg-color;
            transition: border-color .1s ease-out;
        }
    }

    /* Border around Freelancer bird icon.
        1. Applies a light blue border on the left hand side of the button to override the darker blue border.
    */

    &::after {
        display: block;
        position: absolute;
        z-index: 3;
        top: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid @primary-color-dark;
        border-right: 0 none;
        border-radius: 3px 0 0 3px;
        background-image: none;
        content: '';
    }

    /* Freelancer bird icon overrides.
        1. Applied to different sizes of the button to resize the bird icon.
        2. Different arrow sizes are also included in :after.
    */
    &.btn-mini {
        [class*="fl-icon-"] {
            width: 24px;
            line-height: 28px;
            font-size: 17px;
            margin-top: 0;

            &::after {
                right: -5px;
                margin-top: -4px;
                border-width: 4px 0 4px 5px;
            }
        }
    }

    &.btn-small {
        [class*="fl-icon-"] {
            width: 30px;
            line-height: 32px;
            font-size: 20px;

            &::after {
                right: -5px;
                border-width: 4px 0 4px 5px;
                margin-top: -4px;
            }
        }
    }

    &.btn-large {
        [class*="fl-icon-"] {
            width: 44px;
            line-height: 46px;
            font-size: 28px;
        }
    }

    &.btn-xlarge {
        [class*="fl-icon-"] {
            width: 54px;
            line-height: 58px;
            font-size: 40px;

            &::after {
                right: -8px;
                margin-top: -9px;
                border-width: 8px 0 8px 8px;
            }
        }
    }



    &.btn-xxlarge {
        [class*="fl-icon-"] {
            width: 62px;
            line-height: 66px;
            font-size: 44px;

            &::after {
                right: -8px;
                margin-top: -9px;
                border-width: 8px 0 8px 8px;
            }
        }
    }

}
