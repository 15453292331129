// Apple App Store and Google Play store link badges.

@import (reference) "theme/variables.less";

.app-store-link {
    display: inline-block;
    height: 44px;
    width: auto;

    /***************************************/
    /* App link Varieties
    /***************************************/
    &.is-badge {
        /**
         * Size/s
        **/
        &-large {
            width: 165px;

            [class*="app-store-link-badge-"] {
                height: 53px;
            }
        }
    }
}
