/**
 * Input
 *
 * The standard select element
 *
 * .default-input - Default button
 * .small-input - Small variation
 * .large-input    - Large variation
 * .xlarge-input - Extra Large variation
 * .xxlarge-input - Extra Large variation
 *
 * Markup: default-input.html
 *
 * Styleguide: Forms - Form Elements - Input
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Input sizes */
@small-input-font-size: 13px;
@large-input-font-size: 16px;
@xlarge-input-font-size: 20px;
@xxlarge-input-font-size: 22px;

.input-focus-constructor(@color) {
    border-color: @color;
    box-shadow: 0 0 0 1px @color;
    color: @color;
}

.default-input,
.small-input,
.large-input,
.xlarge-input,
.xxlarge-input,
.default-textarea,
.large-textarea,
.small-textarea {
    .primary-font(); /* Needed for compatibility with fl-icon classes */
    .type(ui);
    background: @site-bg-color;
    border: 1px solid @field-border-color;
    border-radius: @form-field-border-radius;
    box-shadow: 0 0 0 1px transparent;
    color: @input-text-color;
    display: inline-block;
    transition: all 0.1s linear;
    width: 100%;

    /* FIXME: This is necessary because IE 11 has issue with specificity of
    its placeholder color inherited from the input itself. */
    &::placeholder {
        color: @placeholder-text-color;
    }

    &:focus {
        border-color: @field-focus-color;
        box-shadow: 0 0 0 1px @field-focus-color;
        color: @input-text-color;
    }

    &.error {
        &:focus {
            border-color: @alert-color;
            box-shadow: 0 0 0 1px @alert-color;
            color: @alert-color;
        }
    }

    &[disabled] {
        background: @input-bg-disabled;
        color: @input-text-color;
        cursor: not-allowed;
    }

    &[type="number"] {
        padding-right: 0;
    }

    + .add-on {
        border-radius: inherit inherit 0 0;
    }
}

.default-input {
    height: 36px;
    padding: 0 12px;
}

.small-input {
    font-size: @small-input-font-size;
    height: 32px;
    padding: 0 10px;
}

.large-input {
    font-size: @large-input-font-size;
    height: 46px;
    padding: 0 16px;
}

.xlarge-input {
    font-size: @xlarge-input-font-size;
    height: 56px;
    padding: 0 20px;
}

.xxlarge-input {
    border-radius: @form-field-border-radius-xxlarge;
    border-width: 2px;
    font-size: @xxlarge-input-font-size;
    height: 71px;
    padding: 0 20px;
}

/* add an icon to an input field */
.input-icon {
    display: block;
    position: relative;

    &::before {
        color: @septenary-color-light;
        display: inline-block;
        font-size: 20px;
        left: 9px;
        line-height: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 20px;
    }

    input {
        padding-left: 35px;
    }
}

/**
 * Input w/ button - DEPRECATED
 *
 * **Deprecated** See New Input Combo http://patternlibrary.freelancer.com/section-forms.html#kssref-forms-form-elements-input-combo
 *
 * Markup: input-btn-combo-default.html
 *
 * Styleguide: Forms - Form Elements - Inputs
 */

/**
 * Input w/ button - DEPRECATED
 *
 * **Deprecated** See New Input Combo http://patternlibrary.freelancer.com/section-forms.html#kssref-forms-form-elements-input-combo
 *
 * Markup: input-btn-combo-default.html
 *
 * Styleguide: Deprecated - Inputs
 */

.input-btn-combo {
    border-collapse: separate;
    display: flex;
    width: 100%;

    input {
        border-bottom-right-radius: 0;
        border-right-width: 0;
        border-top-right-radius: 0;
        flex-shrink: 1; /* Needed for IE 10 */
        /**
         * min-width needed to normalise FF
         * https://bugzilla.mozilla.org/show_bug.cgi?id=1088586
         */
        min-width: 0;
    }

    a,
    label,
    button {
        align-items: center;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: flex;
        flex-shrink: 0;
        white-space: nowrap;
    }
}

.input-btn-wrapper button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

/*************************************************/
/* text area */

.default-textarea,
.large-textarea,
.small-textarea {
    line-height: 1.5;
    resize: vertical;
    text-indent: 0;
}

.default-textarea {
    min-height: 100px;
    padding: 10px;
}

.large-textarea {
    font-size: @large-input-font-size;
    min-height: 150px;
    padding: 10px 15px;
}

.small-textarea {
    font-size: @small-input-font-size;
    min-height: 100px;
    padding: 4px 9px;
}
