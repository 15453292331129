/* variables - all color and font declarations to go in this file and no where else */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
@import "flux/base/global.less";
@import "flux/modules/flag-icons.less";
@import (reference) "flux/modules/dropdown.less";
@import "flux/modules/buttons.less";
@import "flux/modules/branded-bt.less";
@import "flux/modules/flicon.less";
@import "flux/modules/Icon.less";
@import "flux/modules/Breadcrumbs.less";
@import "flux/modules/Loader.less";
@import "flux/modules/ImageThumbnail.less";
@import "flux/modules/ResponsiveEmbed.less";
@import "flux/layout/section-inner.less";
@import "flux/modules/AnnouncementBar.less";
@import "libs/hint/hint.less";
@import "flux/modules/Card.less";
@import "flux/modules/SiteStat.less";
@import "flux/modules/fl-form.less";
@import "flux/modules/fl-form--large.less";
// Putting it under `.pinky-template` makes it super hard to be more specific
@import "flux/layout/header-T136271.less";

.pinky-template {
    /* Mixin libraries */
    @import (reference) "bootstrap/2.32/variables.less";
    @import (reference) "bootstrap/2.32/mixins.less";
    @import "bootstrap/2.32/utilities.less";
    @import "bootstrap/2.32/component-animations.less";
    @import "bootstrap/2.32/dropdowns.less";
    @import "bootstrap/2.32/modals.less";
    @import "bootstrap/2.32/popovers.less";
    @import "bootstrap/2.32/tooltip.less";
    @import "bootstrap/2.32/alerts.less";
    @import "bootstrap/2.32/close.less";
    @import "bootstrap/2.32/navs.less";

    /* - Legacy - styles for chunks of old html from the site waiting to be rewritten - to be phased out */
    @import "flux/legacy/legacy-components.less";
    @import "flux/legacy/legacy-forms.less";

    /* Separation of CSS concerns as per the SMACSS methodology - http://smacss.com/ */

    /* - Bootstrap module overrides */
    @import "flux/modules/bootstrap-custom/bootstrap-custom.less";

    /* - Modules - Reusable HTML chunks */
    @import "flux/modules/country-selector.less";
    @import (multiple) "flux/modules/inputs.less";
    @import "flux/modules/loading-icon.less";

    /* header needs to be loaded on top to  display warning for legacy ie */
    @import "flux/layout/header.less";
    @import "flux/modules/online-status.less";
    @import "flux/modules/tabs.less";
    @import "flux/modules/hr-divider.less";
    @import "flux/modules/profile-pic.less";
    @import "flux/modules/app-store-links.less";
    @import "flux/modules/fl-social-media-links.less";
    @import "flux/modules/alert.less";
    @import "flux/modules/FacebookSignUpModal.less";

    /* - Layouts - HTML framework which house components */

    @import "flux/layout/Footer.less";
    @import "flux/layout/LoggedOutFooter.less";
    @import "layout/login-signup-module.less";
}

@import "flux/modules/Rating.less";
@import "flux/modules/Earnings.less";
@import "flux/modules/responsive-modal.less";

/*** !!!!! IMPORTANT NOTE IF YOU ARE UPDATING FONTS !!!!!!! ***/
/*** 1. make sure you increment the parameter at the end of this path  */

@import "icons/FontIcon.less"; /* 1. */

@import "flux/legacy/feedback-system.less";
@import "flux/legacy/feedback.less";

/* States */
/* Used to indicate the state of a component, toggled via JS */
@import "flux/states/states.less";

/**
 * All
 *
 * Weight: -10
 *
 * Styleguide: All
 */

/**
 * Sections
 *
 * Modular and reusable layouts
 *
 * Weight: -8
 *
 * Styleguide: Sections
 */

/**
 * Deprecated
 *
 * All modules that are deprecated and are to be phased out.
 *
 * Weight: 10
 *
 * Styleguide: Deprecated
 */
