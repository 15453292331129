@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

// ** Private Mixin/s ** //
.socialMediaIcon-dimension(@dimension) {
    height: @dimension;
    width: @dimension;
}

/***************************************/
/* FL Social Media Icon
/***************************************/
.fl-social-media-links {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;

    > li {
        display: inline-block;
        margin-left: 6px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.fl-social-media-icon {
    background-color: @quaternary-color-xlight;
    border-radius: 50%;
    .socialMediaIcon-dimension(24px);
    display: block;
    position: relative;
    text-align: center;
    .animated(.3s);

    [class*="flicon-"] {
        fill: @quaternary-color-dark;
        height: 70%;
        stroke-width: 0;
        width: 70%;
        .horizontal-vertical-align();
    }

    .flicon-youtube {
        height: 80%;
        width: 80%;
    }

    &:hover {
        [class*="flicon-"] {
            fill: @quaternary-color-xxxdark;
        }
    }

    // Sizes
    &.is-iconLarge {
        .socialMediaIcon-dimension(50px);

        [class*="flicon-"] {
            height: 55%;
            width: 55%;
        }
    }

    // Colors
    &.is-iconLight {
        background-color: @septenary-color-xlight;

        [class*="flicon-"] {
            fill: @septenary-color-xdark;
        }
    }

    &.is-iconXLight {
        background-color: @septenary-color-xxxlight;
    }

    // SVG social icon Colors
    &.is-iconColored {
        .flicon-facebook {
            fill: @facebook-color;
        }

        .flicon-twitter {
            fill: @twitter-color;
        }

        .flicon-reddit {
            fill: @reddit-color;
        }

        .flicon-email-logo {
            fill: @septenary-color-xlight;
        }
    }
}
