.flag-sprite,
[data-flag=true] {
    background-image: url("/static/css/images/sprite-flag-var02.png");
}

.small-flag {
    &:extend(.flag-sprite);
    display: inline-block;
    height: 13px;
    width: 18px;
}

.afghanistan {
    &:extend(.flag-sprite);
    background-position: 0 0px;
}

.aland-islands {
    &:extend(.flag-sprite);
    background-position: 0 -13px;
}

.albania {
    &:extend(.flag-sprite);
    background-position: 0 -26px;
}

.algeria {
    &:extend(.flag-sprite);
    background-position: 0 -39px;
}

.american-samoa {
    &:extend(.flag-sprite);
    background-position: 0 -52px;
}

.andorra {
    &:extend(.flag-sprite);
    background-position: 0 -65px;
}

.angola {
    &:extend(.flag-sprite);
    background-position: 0 -78px;
}

.anguilla {
    &:extend(.flag-sprite);
    background-position: 0 -91px;
}

.antarctica {
    &:extend(.flag-sprite);
    background-position: 0 -104px;
}

.antigua-and-barbuda {
    &:extend(.flag-sprite);
    background-position: 0 -117px;
}

.argintina,
.argentina {
    &:extend(.flag-sprite);
    background-position: 0 -130px;
}

.armenia {
    &:extend(.flag-sprite);
    background-position: 0 -143px;
}

.aruba {
    &:extend(.flag-sprite);
    background-position: 0 -156px;
}

.australia {
    &:extend(.flag-sprite);
    background-position: 0 -169px;
}

.austria {
    &:extend(.flag-sprite);
    background-position: 0 -182px;
}

.azerbaijan {
    &:extend(.flag-sprite);
    background-position: 0 -195px;
}

.bahamas {
    &:extend(.flag-sprite);
    background-position: 0 -208px;
}

.bahrain {
    &:extend(.flag-sprite);
    background-position: 0 -221px;
}

.bangladesh {
    &:extend(.flag-sprite);
    background-position: 0 -234px;
}

.barbados {
    &:extend(.flag-sprite);
    background-position: 0 -247px;
}

.belarus {
    &:extend(.flag-sprite);
    background-position: 0 -260px;
}

.belgium {
    &:extend(.flag-sprite);
    background-position: 0 -273px;
}

.belize {
    &:extend(.flag-sprite);
    background-position: 0 -286px;
}

.benin {
    &:extend(.flag-sprite);
    background-position: 0 -299px;
}

.bermuda {
    &:extend(.flag-sprite);
    background-position: 0 -312px;
}

.bhutan {
    &:extend(.flag-sprite);
    background-position: 0 -325px;
}

.bolivia {
    &:extend(.flag-sprite);
    background-position: 0 -338px;
}

.bonaire-saint-eustatius-and-saba {
    &:extend(.flag-sprite);
    background-position: 0 -351px;
}

.bosnia-and-herzegovina {
    &:extend(.flag-sprite);
    background-position: 0 -364px;
}

.botswana {
    &:extend(.flag-sprite);
    background-position: 0 -377px;
}

.bouvet-island {
    &:extend(.flag-sprite);
    background-position: 0 -390px;
}

.brazil {
    &:extend(.flag-sprite);
    background-position: 0 -403px;
}

.british-indian-ocean-territory {
    &:extend(.flag-sprite);
    background-position: 0 -416px;
}

.brunei-darussalam {
    &:extend(.flag-sprite);
    background-position: 0 -429px;
}

.bulgaria {
    &:extend(.flag-sprite);
    background-position: 0 -442px;
}

.burkina-faso {
    &:extend(.flag-sprite);
    background-position: 0 -455px;
}

.burundi {
    &:extend(.flag-sprite);
    background-position: 0 -468px;
}

.cambodia {
    &:extend(.flag-sprite);
    background-position: 0 -481px;
}

.cameroon {
    &:extend(.flag-sprite);
    background-position: 0 -494px;
}

.canada {
    &:extend(.flag-sprite);
    background-position: 0 -507px;
}

.cape-verde {
    &:extend(.flag-sprite);
    background-position: 0 -520px;
}

.cayman-islands {
    &:extend(.flag-sprite);
    background-position: 0 -533px;
}

.central-african-republic {
    &:extend(.flag-sprite);
    background-position: 0 -546px;
}

.chad {
    &:extend(.flag-sprite);
    background-position: 0 -559px;
}

.chile {
    &:extend(.flag-sprite);
    background-position: 0 -572px;
}

.china {
    &:extend(.flag-sprite);
    background-position: 0 -585px;
}

.christmas-island {
    &:extend(.flag-sprite);
    background-position: 0 -598px;
}

.cocos-keeling-islands {
    &:extend(.flag-sprite);
    background-position: 0 -611px;
}

.colombia {
    &:extend(.flag-sprite);
    background-position: 0 -624px;
}

.comoros {
    &:extend(.flag-sprite);
    background-position: 0 -637px;
}

.congo {
    &:extend(.flag-sprite);
    background-position: 0 -650px;
}

.congo-the-democratic-republic-of-the {
    &:extend(.flag-sprite);
    background-position: 0 -663px;
}

.cook-islands {
    &:extend(.flag-sprite);
    background-position: 0 -676px;
}

.costarica,
.costa-rica {
    &:extend(.flag-sprite);
    background-position: 0 -689px;
}

.cote-divoire {
    &:extend(.flag-sprite);
    background-position: 0 -702px;
}

.croatia {
    &:extend(.flag-sprite);
    background-position: 0 -715px;
}

.cuba {
    &:extend(.flag-sprite);
    background-position: 0 -728px;
}

.curacao {
    &:extend(.flag-sprite);
    background-position: 0 -741px;
}

.cyprus {
    &:extend(.flag-sprite);
    background-position: 0 -754px;
}

.cz,
.czech-republic {
    &:extend(.flag-sprite);
    background-position: 0 -767px;
}

.denmark {
    &:extend(.flag-sprite);
    background-position: 0 -780px;
}

.djibouti {
    &:extend(.flag-sprite);
    background-position: 0 -793px;
}

.dominica {
    &:extend(.flag-sprite);
    background-position: 0 -806px;
}

.d-republic,
.dominican-republic {
    &:extend(.flag-sprite);
    background-position: 0 -819px;
}

.eduador,
.ecuador {
    &:extend(.flag-sprite);
    background-position: 0 -832px;
}

.egypt {
    &:extend(.flag-sprite);
    background-position: 0 -845px;
}

.el-salvador {
    &:extend(.flag-sprite);
    background-position: 0 -858px;
}

.equatorial-guinea {
    &:extend(.flag-sprite);
    background-position: 0 -871px;
}

.eritrea {
    &:extend(.flag-sprite);
    background-position: 0 -884px;
}

.estonia {
    &:extend(.flag-sprite);
    background-position: 0 -897px;
}

.ethiopia {
    &:extend(.flag-sprite);
    background-position: 0 -910px;
}

.falkland-islands-malvinas {
    &:extend(.flag-sprite);
    background-position: 0 -923px;
}

.faroe-islands {
    &:extend(.flag-sprite);
    background-position: 0 -936px;
}

.fiji {
    &:extend(.flag-sprite);
    background-position: 0 -949px;
}

.finland {
    &:extend(.flag-sprite);
    background-position: 0 -962px;
}

.france {
    &:extend(.flag-sprite);
    background-position: 0 -975px;
}

.french-guiana {
    &:extend(.flag-sprite);
    background-position: 0 -988px;
}

.french-polynesia {
    &:extend(.flag-sprite);
    background-position: 0 -1001px;
}

.french-southern-territories {
    &:extend(.flag-sprite);
    background-position: 0 -1014px;
}

.gabon {
    &:extend(.flag-sprite);
    background-position: 0 -1027px;
}

.gambia {
    &:extend(.flag-sprite);
    background-position: 0 -1040px;
}

.georgia {
    &:extend(.flag-sprite);
    background-position: 0 -1053px;
}

.germany {
    &:extend(.flag-sprite);
    background-position: 0 -1066px;
}

.ghana {
    &:extend(.flag-sprite);
    background-position: 0 -1079px;
}

.gibraltar {
    &:extend(.flag-sprite);
    background-position: 0 -1092px;
}

.greece {
    &:extend(.flag-sprite);
    background-position: 0 -1105px;
}

.greenland {
    &:extend(.flag-sprite);
    background-position: 0 -1118px;
}

.grenada {
    &:extend(.flag-sprite);
    background-position: 0 -1131px;
}

.guadeloupe {
    &:extend(.flag-sprite);
    background-position: 0 -1144px;
}

.guam {
    &:extend(.flag-sprite);
    background-position: 0 -1157px;
}

.guatemala {
    &:extend(.flag-sprite);
    background-position: 0 -1170px;
}

.guernsey {
    &:extend(.flag-sprite);
    background-position: 0 -1183px;
}

.guinea {
    &:extend(.flag-sprite);
    background-position: 0 -1196px;
}

.guinea-bissau {
    &:extend(.flag-sprite);
    background-position: 0 -1209px;
}

.guyana {
    &:extend(.flag-sprite);
    background-position: 0 -1222px;
}

.haiti {
    &:extend(.flag-sprite);
    background-position: 0 -1235px;
}

.heard-island-and-mcdonald-islands {
    &:extend(.flag-sprite);
    background-position: 0 -1248px;
}

.holy-see-vatican-city-state {
    &:extend(.flag-sprite);
    background-position: 0 -1261px;
}

.honduras {
    &:extend(.flag-sprite);
    background-position: 0 -1274px;
}

.hk,
.hong-kong {
    &:extend(.flag-sprite);
    background-position: 0 -1287px;
}

.hungary {
    &:extend(.flag-sprite);
    background-position: 0 -1300px;
}

.iceland {
    &:extend(.flag-sprite);
    background-position: 0 -1313px;
}

.india {
    &:extend(.flag-sprite);
    background-position: 0 -1326px;
}

.indo,
.indonesia {
    &:extend(.flag-sprite);
    background-position: 0 -1339px;
}

.iran-islamic-republic-of {
    &:extend(.flag-sprite);
    background-position: 0 -1352px;
}

.iraq {
    &:extend(.flag-sprite);
    background-position: 0 -1365px;
}

.ireland {
    &:extend(.flag-sprite);
    background-position: 0 -1378px;
}

.isle-of-man {
    &:extend(.flag-sprite);
    background-position: 0 -1391px;
}

.israel {
    &:extend(.flag-sprite);
    background-position: 0 -1404px;
}

.italy {
    &:extend(.flag-sprite);
    background-position: 0 -1417px;
}

.jamaica {
    &:extend(.flag-sprite);
    background-position: 0 -1430px;
}

.japan {
    &:extend(.flag-sprite);
    background-position: 0 -1443px;
}

.jersey {
    &:extend(.flag-sprite);
    background-position: 0 -1456px;
}

.jordan {
    &:extend(.flag-sprite);
    background-position: 0 -1469px;
}

.kazakhstan {
    &:extend(.flag-sprite);
    background-position: 0 -1482px;
}

.kenya {
    &:extend(.flag-sprite);
    background-position: 0 -1495px;
}

.kiribati {
    &:extend(.flag-sprite);
    background-position: 0 -1508px;
}

.korea-democratic-peoples-republic-of {
    &:extend(.flag-sprite);
    background-position: 0 -1521px;
}

.korea-republic-of {
    &:extend(.flag-sprite);
    background-position: 0 -1534px;
}

.kosovo {
    &:extend(.flag-sprite);
    background-position: 0 -1547px;
}

.kuwait {
    &:extend(.flag-sprite);
    background-position: 0 -1560px;
}

.kyrgyzstan {
    &:extend(.flag-sprite);
    background-position: 0 -1573px;
}

.lao-peoples-democratic-republic {
    &:extend(.flag-sprite);
    background-position: 0 -1586px;
}

.latvia {
    &:extend(.flag-sprite);
    background-position: 0 -1599px;
}

.lebanon {
    &:extend(.flag-sprite);
    background-position: 0 -1612px;
}

.lesotho {
    &:extend(.flag-sprite);
    background-position: 0 -1625px;
}

.liberia {
    &:extend(.flag-sprite);
    background-position: 0 -1638px;
}

.libya {
    &:extend(.flag-sprite);
    background-position: 0 -1651px;
}

.liechtenstein {
    &:extend(.flag-sprite);
    background-position: 0 -1664px;
}

.lithuania {
    &:extend(.flag-sprite);
    background-position: 0 -1677px;
}

.luxembourg {
    &:extend(.flag-sprite);
    background-position: 0 -1690px;
}

.macau {
    &:extend(.flag-sprite);
    background-position: 0 -1703px;
}

.macedonia {
    &:extend(.flag-sprite);
    background-position: 0 -1716px;
}

.madagascar {
    &:extend(.flag-sprite);
    background-position: 0 -1729px;
}

.malawi {
    &:extend(.flag-sprite);
    background-position: 0 -1742px;
}

.malaysia {
    &:extend(.flag-sprite);
    background-position: 0 -1755px;
}

.maldives {
    &:extend(.flag-sprite);
    background-position: 0 -1768px;
}

.mali {
    &:extend(.flag-sprite);
    background-position: 0 -1781px;
}

.malta {
    &:extend(.flag-sprite);
    background-position: 0 -1794px;
}

.marshall-islands {
    &:extend(.flag-sprite);
    background-position: 0 -1807px;
}

.martinique {
    &:extend(.flag-sprite);
    background-position: 0 -1820px;
}

.mauritania {
    &:extend(.flag-sprite);
    background-position: 0 -1833px;
}

.mauritius {
    &:extend(.flag-sprite);
    background-position: 0 -1846px;
}

.mayotte {
    &:extend(.flag-sprite);
    background-position: 0 -1859px;
}

.mexico {
    &:extend(.flag-sprite);
    background-position: 0 -1872px;
}

.micronesia-federated-states-of {
    &:extend(.flag-sprite);
    background-position: 0 -1885px;
}

.moldova-republic-of {
    &:extend(.flag-sprite);
    background-position: 0 -1898px;
}

.monaco {
    &:extend(.flag-sprite);
    background-position: 0 -1911px;
}

.mongolia {
    &:extend(.flag-sprite);
    background-position: 0 -1924px;
}

.montenegro {
    &:extend(.flag-sprite);
    background-position: 0 -1937px;
}

.montserrat {
    &:extend(.flag-sprite);
    background-position: 0 -1950px;
}

.morocco {
    &:extend(.flag-sprite);
    background-position: 0 -1963px;
}

.mozambique {
    &:extend(.flag-sprite);
    background-position: 0 -1976px;
}

.myanmar {
    &:extend(.flag-sprite);
    background-position: 0 -1989px;
}

.namibia {
    &:extend(.flag-sprite);
    background-position: 0 -2002px;
}

.nauru {
    &:extend(.flag-sprite);
    background-position: 0 -2015px;
}

.nepal {
    &:extend(.flag-sprite);
    background-position: 0 -2028px;
}

.netherlands {
    &:extend(.flag-sprite);
    background-position: 0 -2041px;
}

.new-caledonia {
    &:extend(.flag-sprite);
    background-position: 0 -2054px;
}

.nz,
.new-zealand {
    &:extend(.flag-sprite);
    background-position: 0 -2067px;
}

.nicaragua {
    &:extend(.flag-sprite);
    background-position: 0 -2080px;
}

.niger {
    &:extend(.flag-sprite);
    background-position: 0 -2093px;
}

.nigeria {
    &:extend(.flag-sprite);
    background-position: 0 -2106px;
}

.niue {
    &:extend(.flag-sprite);
    background-position: 0 -2119px;
}

.norfolk-island {
    &:extend(.flag-sprite);
    background-position: 0 -2132px;
}

.northern-mariana-islands {
    &:extend(.flag-sprite);
    background-position: 0 -2145px;
}

.norway {
    &:extend(.flag-sprite);
    background-position: 0 -2158px;
}

.oman {
    &:extend(.flag-sprite);
    background-position: 0 -2171px;
}

.pakistan {
    &:extend(.flag-sprite);
    background-position: 0 -2184px;
}

.palau {
    &:extend(.flag-sprite);
    background-position: 0 -2197px;
}

.palestinian-territory {
    &:extend(.flag-sprite);
    background-position: 0 -2210px;
}

.panama {
    &:extend(.flag-sprite);
    background-position: 0 -2223px;
}

.papua-new-guinea {
    &:extend(.flag-sprite);
    background-position: 0 -2236px;
}

.paraguay {
    &:extend(.flag-sprite);
    background-position: 0 -2249px;
}

.peru {
    &:extend(.flag-sprite);
    background-position: 0 -2262px;
}

.philippines {
    &:extend(.flag-sprite);
    background-position: 0 -2275px;
}

.pitcairn-islands {
    &:extend(.flag-sprite);
    background-position: 0 -2288px;
}

.poland {
    &:extend(.flag-sprite);
    background-position: 0 -2301px;
}

.portugal {
    &:extend(.flag-sprite);
    background-position: 0 -2314px;
}

.puerto-rico {
    &:extend(.flag-sprite);
    background-position: 0 -2327px;
}

.qatar {
    &:extend(.flag-sprite);
    background-position: 0 -2340px;
}

.reunion {
    &:extend(.flag-sprite);
    background-position: 0 -2353px;
}

.romania {
    &:extend(.flag-sprite);
    background-position: 0 -2366px;
}

.russia,
.russian-federation {
    &:extend(.flag-sprite);
    background-position: 0 -2379px;
}

.rwanda {
    &:extend(.flag-sprite);
    background-position: 0 -2392px;
}

.saint-barthele-my {
    &:extend(.flag-sprite);
    background-position: 0 -2405px;
}

.saint-helena {
    &:extend(.flag-sprite);
    background-position: 0 -2418px;
}

.saint-kitts-and-nevis {
    &:extend(.flag-sprite);
    background-position: 0 -2431px;
}

.saint-lucia {
    &:extend(.flag-sprite);
    background-position: 0 -2444px;
}

.saint-martin {
    &:extend(.flag-sprite);
    background-position: 0 -2457px;
}

.saint-pierre-and-miquelon {
    &:extend(.flag-sprite);
    background-position: 0 -2470px;
}

.saint-vincent-and-the-grenadines {
    &:extend(.flag-sprite);
    background-position: 0 -2483px;
}

.samoa {
    &:extend(.flag-sprite);
    background-position: 0 -2496px;
}

.san-marino {
    &:extend(.flag-sprite);
    background-position: 0 -2509px;
}

.sao-tome-and-principe {
    &:extend(.flag-sprite);
    background-position: 0 -2522px;
}

.saudi-arabia {
    &:extend(.flag-sprite);
    background-position: 0 -2535px;
}

.senegal {
    &:extend(.flag-sprite);
    background-position: 0 -2548px;
}

.serbia {
    &:extend(.flag-sprite);
    background-position: 0 -2561px;
}

.seychelles {
    &:extend(.flag-sprite);
    background-position: 0 -2574px;
}

.sierra-leone {
    &:extend(.flag-sprite);
    background-position: 0 -2587px;
}

.singapore {
    &:extend(.flag-sprite);
    background-position: 0 -2600px;
}

.sint-maarten-dutch-part {
    &:extend(.flag-sprite);
    background-position: 0 -2613px;
}

.slovakia {
    &:extend(.flag-sprite);
    background-position: 0 -2626px;
}

.slovenia {
    &:extend(.flag-sprite);
    background-position: 0 -2639px;
}

.solomon-islands {
    &:extend(.flag-sprite);
    background-position: 0 -2652px;
}

.somalia {
    &:extend(.flag-sprite);
    background-position: 0 -2665px;
}

.south-africa {
    &:extend(.flag-sprite);
    background-position: 0 -2678px;
}

.south-georgia-and-the-south-sandwich-islands {
    &:extend(.flag-sprite);
    background-position: 0 -2691px;
}

.spain {
    &:extend(.flag-sprite);
    background-position: 0 -2704px;
}

.sri-lanka {
    &:extend(.flag-sprite);
    background-position: 0 -2717px;
}

.sudan {
    &:extend(.flag-sprite);
    background-position: 0 -2730px;
}

.suriname {
    &:extend(.flag-sprite);
    background-position: 0 -2743px;
}

.svalbard-and-jan-mayen {
    &:extend(.flag-sprite);
    background-position: 0 -2756px;
}

.swaziland {
    &:extend(.flag-sprite);
    background-position: 0 -2769px;
}

.sweden {
    &:extend(.flag-sprite);
    background-position: 0 -2782px;
}

.switzerland {
    &:extend(.flag-sprite);
    background-position: 0 -2795px;
}

.syrian-arab-republic {
    &:extend(.flag-sprite);
    background-position: 0 -2808px;
}

.taiwan {
    &:extend(.flag-sprite);
    background-position: 0 -2821px;
}

.tajikistan {
    &:extend(.flag-sprite);
    background-position: 0 -2834px;
}

.tanzania-united-republic-of {
    &:extend(.flag-sprite);
    background-position: 0 -2847px;
}

.thailand {
    &:extend(.flag-sprite);
    background-position: 0 -2860px;
}

.timor-leste {
    &:extend(.flag-sprite);
    background-position: 0 -2873px;
}

.togo {
    &:extend(.flag-sprite);
    background-position: 0 -2886px;
}

.tokelau {
    &:extend(.flag-sprite);
    background-position: 0 -2899px;
}

.tonga {
    &:extend(.flag-sprite);
    background-position: 0 -2912px;
}

.trinidad-and-tobago {
    &:extend(.flag-sprite);
    background-position: 0 -2925px;
}

.tunisia {
    &:extend(.flag-sprite);
    background-position: 0 -2938px;
}

.turkey {
    &:extend(.flag-sprite);
    background-position: 0 -2951px;
}

.turkmenistan {
    &:extend(.flag-sprite);
    background-position: 0 -2964px;
}

.turks-and-caicos-islands {
    &:extend(.flag-sprite);
    background-position: 0 -2977px;
}

.tuvalu {
    &:extend(.flag-sprite);
    background-position: 0 -2990px;
}

.uganda {
    &:extend(.flag-sprite);
    background-position: 0 -3003px;
}

.ukraine {
    &:extend(.flag-sprite);
    background-position: 0 -3016px;
}

.united-arab-emirates {
    &:extend(.flag-sprite);
    background-position: 0 -3029px;
}

.uk,
.united-kingdom {
    &:extend(.flag-sprite);
    background-position: 0 -3042px;
}

.usa,
.united-states {
    &:extend(.flag-sprite);
    background-position: 0 -3055px;
}

.united-states-minor-outlying-islands {
    &:extend(.flag-sprite);
    background-position: 0 -3068px;
}

.uruguay {
    &:extend(.flag-sprite);
    background-position: 0 -3081px;
}

.uzbekistan {
    &:extend(.flag-sprite);
    background-position: 0 -3094px;
}

.vanuatu {
    &:extend(.flag-sprite);
    background-position: 0 -3107px;
}

.venezuela {
    &:extend(.flag-sprite);
    background-position: 0 -3120px;
}

.vietnam {
    &:extend(.flag-sprite);
    background-position: 0 -3133px;
}

.virgin-islands-british {
    &:extend(.flag-sprite);
    background-position: 0 -3146px;
}

.virgin-islands-us {
    &:extend(.flag-sprite);
    background-position: 0 -3159px;
}

.wallis-and-futuna {
    &:extend(.flag-sprite);
    background-position: 0 -3172px;
}

.western-sahara {
    &:extend(.flag-sprite);
    background-position: 0 -3185px;
}

.yemen {
    &:extend(.flag-sprite);
    background-position: 0 -3198px;
}

.zambia {
    &:extend(.flag-sprite);
    background-position: 0 -3211px;
}

.zimbabwe {
    &:extend(.flag-sprite);
    background-position: 0 -3224px;
}

.eu,
.europe-union {
    &:extend(.flag-sprite);
    background-position: 0 -3237px;
}
