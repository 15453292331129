/*
 * 1. Overrides pinhp's form element style
 * 2. Overrides fl-form styles
 * 3. Overrides forms.less input margins
 * 4. Overrides repsonsive-modal
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "Loader.less";
@import "ImageThumbnail.less";

/* 4. */
.FacebookSignUpModal.modal {
    display: block;
    margin-left: -195px;
    max-width: 390px;

    @media only screen and (max-width: 600px) {
        margin: 0;
        max-width: none;
    }
}

.FacebookSignUpModal {
    @this: FacebookSignUpModal;

    .@{this}-header {
        border-bottom: none;
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
    }

    &-loader {
        margin-bottom: 24px;
        margin-top: 24px;
    }

    &-heading {
        .type(heading3);
        font-weight: bold;
    }

    &-close {
        .flex();
        .flex-justify(center);
        .flex-align-items(center);
        font-size: 20px;
        height: 40px;
        line-height: 2;
        position: absolute;
        right: 0;
        top: 6px;
        width: 40px;
    }

    &-avatar {
        margin-top: 20px;
    }

    &-avatarImage {
        height: 100px;
        margin: 0 auto;
        width: 100px;
    }

    &-content {
        border-top: 1px solid @border-color-light;
        padding-top: 16px;
    }

    &-method {
        margin-bottom: 12px;
        text-align: center;
    }

    &-input {
        margin-bottom: 9px;
        width: 100%;
    }

    &-radio {
        display: inline-block;
        font-weight: normal; /* 2. */

        input[type="radio"] {
            display: inline-block;
            margin-right: 10px;
            margin-top: 3px;
            vertical-align: top;
        }
    }

    .@{this}-radio ~ .@{this}-radio {
        margin-left: 30px;
    }

    &-radioButtonGroup {
        font-size: 0;
    }

    &-lookingForButton {
        width: 50%;
    }

    &-ctaButton {
        width: 100%;
    }

    &-form { /* 1. */
        margin: 0;
        max-width: none;

        .form-alert {
            margin: 5px 0;
        }

        .is-valid .form-alert {
            color: @field-valid-color;
        }

        .is-invalid .form-alert {
            color: @field-error-color;
        }
    }

    &-forgotPassword {
        display: inline-block;
        margin-top: 5px;
    }

    &-input { /* 3. */
        margin: 0;
    }

    &-emailConfirmation {
        display: flex;
        justify-content: space-between;
    }
}
