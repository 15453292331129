@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "flux/modules/hr-divider.less";
@import "flux/modules/responsive-modal.less";

/* Animations */
@import "mixins/animated/fadeIn.less";
@import "mixins/animated/bounceOutUp.less";

.loading-icon-wrapper {
    padding: 20px;
    text-align: center;
}

.header-login-form,
.header-login-form-modal {
    background: @septenary-color-xxxlight;
    margin: 0 auto;
    max-width: 390px;
    position: relative;

    .fl-form fieldset {
        margin-bottom: 0;
    }

    .modal {
        &-body {
            max-height: none;
            overflow-y: inherit;
            padding: 20px 36px;
        }

        &-footer {
            background: none transparent;
            border-top: none;
            padding: 10px 20px 20px;
        }
    }

    .btn {
        width: 100%;
    }

    .signup-objective-label {
        white-space: nowrap;
        width: 50%;
    }
}

.header-login-form {
    .card();
    margin-top: 20px;

    &-modal {
        margin-left: -195px;
    }

    .coupon-step {
        &-toggle {
            margin: 10px 0;
            text-align: center;
        }

        &-inner {
            border: 1px solid @border-color-dark;
            border-radius: @border-radius;
            padding: 20px;
        }

        /* State for the coupon step to toggle content */
        &.is-coupon-step-hidden {
            .coupon-step-inner {
                display: none;
            }
        }
    }
}

.modal-header {
    border-bottom: 0;

    &-logo {
        border-bottom: 1px solid @quaternary-color-xxxxlight;
        margin: 3px 14px 2px;
        text-align: center;
    }

    .flicon-logo-fullcolor {
        height: 80px;
        padding-bottom: @spacing-xsmall;
        stroke-width: 0;
        vertical-align: middle;
        width: 196px;
    }

    .modal-title {
        .type(landing);
        line-height: 1;
        margin-top: 20px;
        text-align: center;
    }
}

.modal-download-links {
    bottom: -82px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;

    .app-store-link {
        margin: 0 @spacing-xsmall;
    }
}

.hr-divider {
    margin: 25px 0;

    &::before {
        margin-top: 0;
    }

    &-text {
        color: @quaternary-color;
        font-size: 13px;
    }
}

.login-form {
    &-options {
        .type(ui);
        margin: 10px 0 0;
        overflow: hidden;
    }

    &-remember {
        float: left;
        margin-bottom: 0;
    }

    &-forgot {
        float: right;
    }

    &-signup-link {
        border-top: 1px solid @quaternary-color-xxxxlight;
        display: block;
        line-height: 20px;
        padding-top: 20px;
        text-align: center;
    }

    &-signup-terms {
        display: block;
        margin: 0 auto;
        padding-bottom: 15px;
    }

    .app-store-link {
        margin: 0 6px;
    }
}

.captcha-form {
    text-align: center;

    &-recaptchaContainer {
        display: inline-block;
    }

    &-submit {
        display: block;
        margin-top: @spacing-xsmall;
        width: 100%;
    }
}
