@import (reference) "libs/mixins.less";

.spin() {
    -webkit-animation-name: spin;
    animation-name: spin;
}

@-webkit-keyframes spin {
    0% {
        .transform(rotate(0));
    }

    100% {
        .transform(rotate(360deg));
    }
}

@keyframes spin {
    0% {
        .transform(rotate(0));
    }

    100% {
        .transform(rotate(360deg));
    }
}
