@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Online Status indicator, used in chatbox, etc. */

@border-radius-status: 50%;

.online-status {
    .transition(.5s, ease-in-out);
    background-color: @offline-dot-color;
    border-radius: @border-radius-status;
    display: inline-block;
    height: 7px;
    width: 7px;

    &[data-status="offline"] {
        background-color: @offline-dot-color;
    }

    &[data-status="online"] {
        background-color: @online-color;
    }

    /* sizes */
    &[data-size="large"] {
        border-radius: @border-radius-status;
        height: 12px;
        width: 12px;
    }

    &[data-size="medium"] {
        border-radius: @border-radius-status;
        height: 10px;
        width: 10px;
    }

    &[data-size="small"] {
        border-radius: @border-radius-status;
        height: 8px;
        width: 8px;
    }
}
