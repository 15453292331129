/**
 * Alerts
 *
 * Styleguide: Alerts
 */

/**
 * Bootstrap alert
 *
 * Used to show alert the user to important information
 *
 * .alert-info - Gives information to the user.
 * .alert-success - Information for when the user performs a successful action.
 * .alert-warning - Information to warn the user of a pending action.
 * .alert-error - Warns the user when an error has occured.
 * .alert-bids - Information about a users bid.
 * .alert-common - This alert can be used when there is no suitable alternative from the above list.
 *
 * Markup: bootstrap-alert.html
 *
 * Styleguide: Alerts - Bootstrap Alert
 */

/**
 * Bootstrap alert /w icon
 *
 * Sub-module of the bootstrap alert that includes an icon
 *
 * Markup: bootstrap-alert-with-icon.html
 *
 * Styleguide: Alerts - Bootstrap Alert with icon
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.alert {
    .border-radius(3px, 3px, 3px, 3px);
    .type(ui);
    border: 1px solid;
    line-height: 20px;
    margin-bottom: 10px;
    padding: 10px 15px;
    text-shadow: none;

    .close {
        .opacity(1);
        background: none;
        border: none;
        margin: -2px 10px 0 10px;
        text-shadow: none;
    }

    &.has-icon {
        .border-radius(3px, 3px, 3px, 3px);
        display: inline-block;
        min-height: 40px;
        overflow: hidden;
        padding: (@spacing-small - @spacing-xxsmall) @spacing-small (@spacing-small - @spacing-xxsmall) @spacing-xlarge;
        position: relative;

        .close {
            margin: -7px 1px 0 10px;
            text-shadow: none;
        }
    }

    h1 {
        line-height: 38px; // Same as global - matches font-size
        margin-bottom: 16px;
    }

    h2 {
        line-height: 32px;
        margin-bottom: 14px;
    }

    h3 {
        line-height: 26px;
        margin-bottom: 12px;
    }

    h4 {
        line-height: 22px;
        margin-bottom: 10px;
    }

    h5 {
        line-height: 18px;
        margin-bottom: 10px;
    }

    h6 {
        line-height: 14px;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.alert-icon {
    display: block;
    font-size: 16px;
    height: 16px;
    left: 15px;
    position: absolute;
    top: 12px;
    width: 25px;
}

.alert-success {
    background-color: @success-notify-color-light;
    border-color: @success-notify-color;
    color: @success-notify-content-color;

    [class*="fl-icon-"] {
        color: @success-notify-content-color;
    }

    .close {
        color: @success-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@success-notify-content-color, 15%);
        }
    }
}

.alert-error {
    background-color: @error-notify-color-light;
    border-color: @error-notify-color;
    color: @error-notify-content-color;

    [class*="fl-icon-"] {
        color: @error-notify-content-color;
    }

    .close {
        color: @error-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@error-notify-content-color, 15%);
        }
    }

    a {
        color: @error-notify-content-color;
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            color: @error-notify-content-color;
        }
    }
}

.alert-info {
    background-color: @info-notify-color-light;
    border-color: @info-notify-color;
    color: @info-notify-content-color;

    [class*="fl-icon-"] {
        color: @info-notify-content-color;
    }

    .close {
        color: @info-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@info-notify-content-color, 15%);
        }
    }
}

.alert-warning {
    background-color: @warning-notify-color-light;
    border-color: @warning-notify-color;
    color: @warning-notify-content-color;

    [class*="fl-icon-"] {
        color: @warning-notify-content-color;
    }

    .close {
        color: @warning-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@warning-notify-content-color, 15%);
        }
    }
}

.alert-bids {
    background-color: @bid-notify-color-light;
    border-color: @bid-notify-color;
    color: @bid-notify-content-color;

    [class*="fl-icon-"] {
        color: @bid-notify-content-color;
    }

    .close {
        color: @bid-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@bid-notify-content-color, 15%);
        }
    }
}

.alert-common {
    background-color: @common-notify-color-light;
    border-color: @common-notify-color;
    color: @common-notify-content-color;

    [class*="fl-icon-"] {
        color: @common-notify-content-color;
    }

    .close {
        color: @common-notify-content-color;

        &:hover,
        &:focus {
            color: darken(@common-notify-content-color, 15%);
        }
    }
}

.alert--large {
    .type(landing);
}
