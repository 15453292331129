@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "bootstrap/variables.less";

/* Animations */
@import "mixins/animated/fadeIn.less";
@import "mixins/animated/fadeInDown.less";
@import "mixins/animated/fadeInUp.less";

// 1. Safari fix - used border radius as overflow: hidden; on parent doesn't work.
// 2. Remove top margin of first element - specifically for <h> tags.


/*************************************************/
/* dropdown */

.dropdown-menu {
    .animated(.1s);
    .fadeInDown;
    font-size: 13px;
    border: none;
    .border-radius(0);
    a {
        &:hover {
            color: @text-color-dark !important;
            background-image: none !important;
            background-color: @dropdown-menu-hover !important;
            filter: none !important;
        }
    }
}

.dropup {
    .dropdown-menu {
        .fadeInUp;
    }
}

/*************************************************/
/* modal */
// we need to add this state for ie8 and ie9
.is-hidden {
    .ie8 &, .ie9 & {
        display: none;
    }
}

.modal-backdrop {
    opacity: 1;
    background-color: @overlay-color;
    .animated(.2s);
}

.modal {
    max-width: 600px;
    .border-radius(6px, 6px, 6px, 6px);
    .type(ui);
    .animated(.4s);
    .fadeIn;
    .animation-delay(.1s);
}

.modal-header {
    padding: 10px 20px;
    position: relative;
    .close {
        border: none;
        background-color: transparent;
        background-image: none;
        color: @text-color-dark;

        &:hover {
            color: @text-color-mid;
        }
    }

    h3 {
        font-size: 16px;
    }
}
.modal-body {
    padding: 20px;
}

.modal-footer {
    padding: 10px 20px;
    background: @septenary-color-xxlight;
}

.modal-title {
    margin: 0;
    .type(heading3);
    font-weight: bold;
}

/************************************************/
/* popover */

.popover {
    .box-shadow-level-02();
}

.custom-popover {
    .box-shadow-level-02();
    max-width: none;
    padding: 0;
    color: @text-color-dark;
    background-color: @site-bg-color;

    .popover-head {
        border-radius: @border-radius @border-radius 0 0;
    }

    .popover-content {
        padding: 0;
    }
}

/************************************************/
/* accordion */

.accordion-group {
    border: none;
}

/************************************************/
/* tooltip */

.tooltip {
    .tooltip-inner {
        padding: 4px 15px;
        font-weight: bold;
    }
}

/************************************************/
/* navbar-widget */

.navbar-widget-arrow-design {
    position: absolute;
    content: '';
    display: inline-block;
    left: 50%;
    margin-left: -8px;
    border: 8px solid transparent;
    border-bottom: none;
    bottom: -8px;
}

.navbar-widget {
    padding: 0;
    border-top: 1px solid @quaternary-color-xxlight;
    border-bottom: 1px solid @quaternary-color-xxlight;
    li {
        float: left;
        // width: 33.2%;
        margin-bottom: 0;
        a {
            display: block;
            padding: 8px 10px;
            color: @septenary-color;
            font-weight: bold;
            position: relative;
            font-size: 13px;
            text-align: center;
            &:hover {
                background-color: @septenary-color-light;
                color: @septenary-color-xxxlight;
                &:after {
                    .navbar-widget-arrow-design;
                    border-top-color: @septenary-color-light;
                }
                &:before {
                    border: none;
                }
            }
            &:before {
                content: '';
                display: inline-block;
                border-right: 1px solid @quaternary-color-xxlight;
                position: absolute;
                // width: 33%;
                min-height: 20px;
                right: -1px;
            }
        }
        &.active {
            a {
                background-color: @septenary-color-xxxdark;
                color: @septenary-color-xxxlight;
                &:hover {
                    background-color: @septenary-color-light;
                    color: @septenary-color-xxxlight;
                    &:after {
                        border-top-color: @septenary-color-light;
                    }
                    &:before {
                        border: none;
                    }
                }
                &:after {
                    .navbar-widget-arrow-design;
                    border-top-color: @septenary-color-xxxdark;
                }
                &:before {
                    border: none;
                }
            }
        }
        &:last-child {
            a {
                &:before {
                    border: none;
                }
            }
        }
    }
    &.one-widget-tab {
        li {
            width: 100%;
        }
    }
    &.two-widget-tab {
        li {
            width: 50%;
        }
    }
    &.three-widget-tab {
        li {
            width: 33.2%;
        }
    }
    &.four-widget-tab {
        li {
            width: 25%;
        }
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    &.negative-margin {
        margin: 15px -14px 10px -13px;
    }
}

.nav-tabs > li > a {
  padding: 8px 0;
  margin-right: 20px;
  border: none;
  color: @text-color-mid;
  .border-radius(0, 0, 0, 0);
  &:hover,
  &:focus {
    border-color: @grayLighter @grayLighter #ddd;
  }
  &:hover {
    background: none;
    color: @link-color;
  }
}

.nav-tabs {
    padding: 0;
    border-bottom: none;
    &.button-bar {
        >li {
            border: none;
            display: inline;
            width: auto !important;
            margin-right: -1px;
            >a {
                border: 1px solid @septenary-color-xlight;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                margin: 0;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                width: 100%;
                display: block;
                padding: 10px 30px;
            }
            list-style-image: url('');
            &.active {
                border: none;
                a {
                    background-color: @septenary-color-light;
                    color: @septenary-color-xxxlight;
                    &:hover {
                        border: 1px solid @septenary-color-xlight;
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
    border: none;
    background: none;
    border-bottom: 2px solid @quaternary-color-dark;
}
