/**
 * Icon
 *
 * Handles displaying of icons. Use appropriate templating tag to insert the SVG
 * for the icon you wish to use on production.
 *
 * .Icon--light - White version of the icon.
 *
 * Markup: icon.html
 *
 * Styleguide: Icons - Icon
 */

/**
 * Icon Library
 *
 * Library of current icons. **This as a reference only, do not use the markup
 * provided here**. Refer to **Icon** above for usage. **Hover** over a
 * particular icon to show its file name, all icons are stored in
 * public/htm/icons/.
 *
 * Markup: icon-library.html
 *
 * Styleguide: Icons - Icon Library
 */

@import (reference) "theme/variables.less";

.Icon {
    @this: Icon;
    display: inline-block;
    fill: @color-icon-dark;
    vertical-align: middle;

    &-image {
        height: 24px;
        vertical-align: middle;
        width: 24px;
    }

    &--light {
        fill: @color-icon-light;
    }

    &--transparent {
        fill: transparent;
    }

    &--mid {
        fill: @septenary-color-xlight;
    }

    &--small {
        .@{this}-image {
            height: 16px;
            width: 16px;
        }
    }

    &--large {
        .@{this}-image {
            height: 32px;
            width: 32px;
        }
    }

    &.is-success {
        fill: @quinary-color;
    }

    &.is-warning {
        fill: @tertiary-color-xxdark;
    }

    &.is-error {
        fill: @senary-color;
    }

    &.is-highLight {
        fill: @primary-color;
    }

    &.is-light {
        fill: @septenary-color-xxxlight;
    }
}
