/**
 * Loader
 *
 * A loader in pure CSS.
 * There will be a possibility to move the loader to an SVG based loader in
 * the future.
 *
 * Markup:
 * <div class="Loader {{modifier_class}}"></div>
 *
 * .Loader--large        - A larger variant of the loader.
 * .Loader--small        - A smaller variant of the loader.
 * .Loader--mini         - An even smaller variant of the loader.
 * .Loader--contained    - Add a background to the loader for the purpose of
 *                         overlaying it to a "crowded" background for better
 *                         visual clarity.
 * .Loader--full         - Turn the loader to a block element so it is centered.
 *
 * Styleguide: Loaders - Loader
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "mixins/animated/spin.less";

/* Private variables */
@Loader-elementPadding: 5px;
@Loader-spinnerSize: 48px;
@Loader-spinnerSize--large: 64px;
@Loader-spinnerSize--small: 24px;
@Loader-spinnerSize--mini: 12px;
@Loader-spinnerBorderWidth: 1px;
@Loader-spinnerBarWidth: 3px;
@Loader-spinnerBarWidth--large: 4px;
@Loader-spinnerBarWidth--small: 2px;
@Loader-spinnerBarWidth--mini: 2px;

/* Private mixin to construct sizes */
.Loader-sizeConstructor(@spinner-size, @spinner-bar-width) {
    width: @spinner-size;
    height: @spinner-size;

    &::after {
        border-width: @spinner-bar-width;
    }
}

.Loader {
    display: inline-block;
    position: relative;
    width: @Loader-spinnerSize;
    height: @Loader-spinnerSize;
    padding: @Loader-elementPadding; /* So .Loader--contained will have a gap between the spinner and the parent element */
    border-radius: 5px;
    vertical-align: middle;

    /* Spinners are made with the pseudoelements. ::before for the gray border and ::after for the spinning blue bar */
    &::before,
    &::after {
        .absolute-cover();
        margin: @Loader-elementPadding;
        border-radius: 50%;
        content: "";
    }

    &::before {
        border: @Loader-spinnerBorderWidth solid @border-color-light;
    }

    &::after {
        border: @Loader-spinnerBarWidth solid transparent;
        border-top-color: @primary-color;
        .animation(spin, 1.5s, linear, 0s, infinite);
    }

    /* Size modifiers */
    &--large {
        .Loader-sizeConstructor(@Loader-spinnerSize--large, @Loader-spinnerBarWidth--large);
    }

    &--small {
        .Loader-sizeConstructor(@Loader-spinnerSize--small, @Loader-spinnerBarWidth--small);
    }

    &--mini {
        .Loader-sizeConstructor(@Loader-spinnerSize--mini, @Loader-spinnerBarWidth--mini);
    }

    /* Modifier to put a container behind the loader */
    &--contained {
        background: @primary-color;

        &::before,
        &::after {
            border-top-color: @septenary-color-xxxlight;
        }
    }

    /* Modifier to make the loader white */
    &--white {
        &::before,
        &::after {
            border-top-color: @septenary-color-xxxlight;
        }
    }

    /* Modifier to convert loader to block and center with margin: 0 auto */
    &--full {
        display: block;
        margin: 0 auto;
    }
}
