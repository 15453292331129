@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.pinky-template {
    .feedback-modal {
        .modal-header {
            border-bottom: 0;

            .modal-title {
                .type(heading2);
                margin-top: 0;
                padding-bottom: 20px;
                text-align: left;
                border-bottom: 1px solid @border-color-dark;
                font-weight: bold;
            }
        }

        .feedback-close {
            position: absolute;
            top: 6px;
            right: 0;
            padding: 15px;
            .type(heading3);
            line-height: 0;
            color: @text-color-mid;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                color: @text-color-dark;
            }
        }

        .modal-body {
            label {
                margin: 20px 0 10px;
            }

            .feedback-error {
                margin-bottom: 0;
                border-color: @alert-color;
                color: @alert-color;
            }
        }

        img {
            margin-left: 100px;
        }

        .user-feedback {
            margin-left: 30px;
        }

        .feedback-review {
            margin-bottom: 20px;

            label {
                font-weight: bold;
            }

            .user-feedback {
                font-style: italic;
            }
        }
    }
}
