/**
 * Ratings
 *
 * Used to display a user's feedback rating on the site
 *
 * .Rating--labeled - displays the average numeral rating in a box on the left side of the stars
 * .Rating--basic - a simplified version of the module wherein it will only show up 1 star with the average rating displayed on the left of the star
 * .Rating--large - a larger variant of the ratigs
 * .Rating--static - force an interactive rating to become static, however data-star_rating must be specified
 *
 * Markup: rating.html
 *
 * Styleguide: Ratings - Ratings
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

@Rating-width: 80px;
@Rating-width-large: 97px;
@Rating-height: @Rating-width/5;
@Rating-height-large: @Rating-width-large/5;

.Rating {
    @this: Rating;

    align-items: center;
    display: inline-flex;

    &::before,
    &::after {
        .type(ui);
        display: inline-block;
        font-weight: normal;
        vertical-align: middle;
    }

    &::before {
        background-color: @user-rating-color;
        border-radius: 2px;
        color: @septenary-color-xxxlight;
        margin-right: 6px;
        padding: 1px 3px 0;
    }

    &::after {
        margin-left: 6px;
    }

    &-total {
        align-items: center;
        display: inline-flex;
        height: @Rating-height;
        justify-content: space-between;
        margin-top: -2px;
        position: relative;
        width: @Rating-width;

        &-input {
            height: 1px;
            left: -10000px;
            overflow: hidden;
            position: absolute;
            top: auto;
            width: 1px;
        }

        &-label {
            cursor: pointer;
            display: inline-block;
            height: @Rating-height;
            margin: 0 !important; /* Prevents margin override from fl-form */
            position: relative;
            width: @Rating-width/5;
            z-index: 1;
        }

        &-input:focus,
        &-label:hover {
            ~ .@{this}-progress::before {
                background-image: url(/static/img/modules/rating/rating-background-hover.svg);
            }
        }

        &-input:nth-child(n+1):checked,
        &-label:nth-child(n+2):hover {
            ~ .@{this}-progress::after {
                width: 20%;
            }
        }

        &-input:nth-child(n+3):checked,
        &-label:nth-child(n+4):hover {
            ~ .@{this}-progress::after {
                width: 40%;
            }
        }

        &-input:nth-child(n+5):checked,
        &-label:nth-child(n+6):hover {
            ~ .@{this}-progress::after {
                width: 60%;
            }
        }

        &-input:nth-child(n+7):checked,
        &-label:nth-child(n+8):hover {
            ~ .@{this}-progress::after {
                width: 80%;
            }
        }

        &-input:nth-child(n+9):checked,
        &-label:nth-child(n+10):hover {
            ~ .@{this}-progress::after {
                width: 100%;
            }
        }
    }

    &-resetIcon {
        // Needed to vertically-center the fl-icon, since we were unable to add
        // class in the directive
        fl-icon {
            display: flex;
        }
    }

    &-progress {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &::before,
        &::after {
            background-repeat: no-repeat;
            background-size: @Rating-width @Rating-height;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        &::before {
            background-image: url(/static/img/modules/rating/rating-background.svg);
        }

        &::after {
            background-image: url(/static/img/modules/rating/rating-foreground.svg);
            width: 0;
        }
    }

    &-review {
        .type(ui);
        color: @text-color-dark;
        display: inline-block;
        margin-left: 19px;
        vertical-align: middle;

        &--hasIcon {
            &::before {
                background: url(/static/img/modules/rating/rating-review-icon.svg) no-repeat;
                content: "";
                display: inline-block;
                height: 13px;
                margin-right: 3px;
                vertical-align: middle;
                width: 11px;
            }
        }
    }

    &[data-star_rating="0.0"] {
        &::before {
            background-color: @quaternary-color-xxxlight;
        }
    }

    &[data-star_rating="0.1"] .@{this}-progress::after,
    &[data-star_rating="0.2"] .@{this}-progress::after,
    &[data-star_rating="0.3"] .@{this}-progress::after,
    &[data-star_rating="0.4"] .@{this}-progress::after,
    &[data-star_rating="0.5"] .@{this}-progress::after {
        width: 10%;
    }

    &[data-star_rating="0.6"] .@{this}-progress::after,
    &[data-star_rating="0.7"] .@{this}-progress::after,
    &[data-star_rating="0.8"] .@{this}-progress::after,
    &[data-star_rating="0.9"] .@{this}-progress::after,
    &[data-star_rating="1.0"] .@{this}-progress::after {
        width: 20%;
    }

    &[data-star_rating="1.1"] .@{this}-progress::after,
    &[data-star_rating="1.2"] .@{this}-progress::after,
    &[data-star_rating="1.3"] .@{this}-progress::after,
    &[data-star_rating="1.4"] .@{this}-progress::after,
    &[data-star_rating="1.5"] .@{this}-progress::after {
        width: 30%;
    }

    &[data-star_rating="1.6"] .@{this}-progress::after,
    &[data-star_rating="1.7"] .@{this}-progress::after,
    &[data-star_rating="1.8"] .@{this}-progress::after,
    &[data-star_rating="1.9"] .@{this}-progress::after,
    &[data-star_rating="2.0"] .@{this}-progress::after {
        width: 40%;
    }

    &[data-star_rating="2.1"] .@{this}-progress::after,
    &[data-star_rating="2.2"] .@{this}-progress::after,
    &[data-star_rating="2.3"] .@{this}-progress::after,
    &[data-star_rating="2.4"] .@{this}-progress::after,
    &[data-star_rating="2.5"] .@{this}-progress::after {
        width: 50%;
    }

    &[data-star_rating="2.6"] .@{this}-progress::after,
    &[data-star_rating="2.7"] .@{this}-progress::after,
    &[data-star_rating="2.8"] .@{this}-progress::after,
    &[data-star_rating="2.9"] .@{this}-progress::after,
    &[data-star_rating="3.0"] .@{this}-progress::after {
        width: 60%;
    }

    &[data-star_rating="3.1"] .@{this}-progress::after,
    &[data-star_rating="3.2"] .@{this}-progress::after,
    &[data-star_rating="3.3"] .@{this}-progress::after,
    &[data-star_rating="3.4"] .@{this}-progress::after,
    &[data-star_rating="3.5"] .@{this}-progress::after {
        width: 70%;
    }

    &[data-star_rating="3.6"] .@{this}-progress::after,
    &[data-star_rating="3.7"] .@{this}-progress::after,
    &[data-star_rating="3.8"] .@{this}-progress::after,
    &[data-star_rating="3.9"] .@{this}-progress::after,
    &[data-star_rating="4.0"] .@{this}-progress::after {
        width: 80%;
    }

    &[data-star_rating="4.1"] .@{this}-progress::after,
    &[data-star_rating="4.2"] .@{this}-progress::after,
    &[data-star_rating="4.3"] .@{this}-progress::after,
    &[data-star_rating="4.4"] .@{this}-progress::after,
    &[data-star_rating="4.5"] .@{this}-progress::after {
        width: 90%;
    }

    &[data-star_rating="4.6"] .@{this}-progress::after,
    &[data-star_rating="4.7"] .@{this}-progress::after,
    &[data-star_rating="4.8"] .@{this}-progress::after,
    &[data-star_rating="4.9"] .@{this}-progress::after,
    &[data-star_rating="5.0"] .@{this}-progress::after {
        width: 100%;
    }

    &--labeled {
        &::before {
            content: attr(data-star_rating);
        }
    }

    &--static {
        .@{this}-total-label,
        .@{this}-total-input {
            display: none;
        }
    }

    &--large {
        &::before,
        &::after {
            .type(landing);
        }

        .@{this}-total {
            height: @Rating-height-large;
            width: @Rating-width-large;

            &-label {
                height: @Rating-height-large;
                width: @Rating-width-large/5;
            }
        }

        .@{this}-progress {
            &::before,
            &::after {
                background-size: @Rating-width-large @Rating-height-large;
            }
        }
    }

    &--basic {
        &::after {
            content: attr(data-star_rating);
        }

        .@{this}-total {
            width: @Rating-width/5;
        }

        .@{this}-progress {
            &::before,
            &::after {
                width: 100%;
            }
        }

        &[data-star_rating^="0"] .@{this}-progress::after {
            width: 0;
        }

        &:not([data-star_rating^="0"]) .@{this}-progress::after {
            width: 100%;
        }

        &.@{this}--large {
            .@{this}-total {
                width: @Rating-width-large/5;
            }
        }
    }
}
