@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.SiteStat {
    &-name {
        .type(detail);
        display: block;
        letter-spacing: 1px;
        text-transform: uppercase;

        &-text,
        &-icon {
            color: @footer-text-color;
            display: inline-block;
            vertical-align: middle;
        }

        &-icon {
            background: none;
            border: 0;
            cursor: pointer;
            display: none;
            line-height: 0;
            padding: 0;
            text-transform: none;

            @media (min-width: @breakpoint-tablet) {
                display: inline-block;
            }
        }
    }

    &-value {
        .type(heading2);
        font-weight: bold;
        margin-bottom: @spacing-xxsmall;
    }
}
