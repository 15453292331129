/*************************************************/
/* country selector */

@import (reference) "flux/legacy/legacy-forms.less";

.location-selector-bt {
    .default-field-skin;
    .icon-right;
    .oneline;
    position: relative;
    padding-right: 34px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    [class*="fl-icon-"] {
        position: absolute;
        right: 15px;
        top: 16px;
    }
}

.selected-language {
    &:before {
        content: '(';
    };
    &:after {
        content: ')';
    };
}

.selected-flag,
.selected-country {
    display: inline-block;
    margin-top: 11px;
}

.selected-flag {
    margin-right: 5px;
}
